import React from 'react';
import { Typography } from 'antd';

const OwerflowNameFormatter = ({ title, className }) => {
  return (
    <Typography
      className={`${className} text-sm w-full overflow-hidden text-ellipsis text-left font-normal`}
    >
      {title || '-'}
    </Typography>
  );
};

export default OwerflowNameFormatter;
