import React from 'react';
import { Flex, Tag } from 'antd';
import useNetworkAccounts from 'store/redux/hooks/useNetworkAccounts';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';

const AccountsTags = ({ default_accounts, closable = false }) => {
  const { accounts } = useNetworkAccounts();

  const AccountsTags = accounts.filter((account) =>
    default_accounts?.find((id) => id === account.id)
  );

  return (
    <Flex gap='smal' wrap>
      {AccountsTags.map((account) => (
        <Tag
          key={`account-${account.id}`}
          bordered={false}
          closable={closable}
          closeIcon={<Icon path={mdiCloseCircle} />}
          className='font-normal text-[11px] bg-background-light h-7 flex items-center rounded-2xl'
        >
          <div className='flex gap-1 items-center text-xs'>
            <img
              width='14'
              src={`/icons/networks/${[account.network]}.svg`}
              alt={account.network}
            />
            <span className='font-medium flex-nowrap text-ellipsis '>{account.name}</span>
          </div>
        </Tag>
      ))}
    </Flex>
  );
};

export default AccountsTags;

AccountsTags.propTypes = {
  default_accounts: PropTypes.array.isRequired,
};
