import React from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useNetworksReportsQuery } from 'store/redux/apis/dashboard.api';
import Widget from 'components/Widget/Widget';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import NetworkWidget from 'components/widgets/components/NetworkWidget';

const BingQuickReports = ({ data, height, movingButton, configuration, index }) => {
  const { date_from, date_to, date_range_key } = useWidgetDate('bing_widget' + index);

  const filters = {
    date_from,
    date_to,
    date_range_key,
    ...data.defaultFilter,
  };

  const { data: bing = {}, isLoading } = useNetworksReportsQuery(filters);

  return (
    <Widget
      bodyStyle={{
        height: height - 60,
      }}
      className='h-full'
      widget_key={'bing_widget' + index}
      title={data.title}
      index={index}
      icon={<img src='/icons/networks/bing.svg' className='w-5 h-5 ' />}
      configuration={configuration}
      disableLimit
      disableStatusFilter
      disableLevelFilter
      network='bing'
      networkSwitch={false}
      default_filter={data?.defaultFilter}
      movingButton={movingButton}
      adsFilter
      adsetFilter
      campaignFilter
      disableDateFilter
      showDatePicker
    >
      <Skeleton loading={isLoading} active={true}>
        <NetworkWidget data={bing?.bing} network='bing' />
      </Skeleton>
    </Widget>
  );
};

export default BingQuickReports;

BingQuickReports.propTypes = {
  movingButton: PropTypes.element.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
