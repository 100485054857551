import React from 'react';
import Widget from 'components/Widget/Widget';
import ShopifyBody from './ShopifyBody';
import PropTypes from 'prop-types';
import { disabled7DaysDate } from 'components/DateRangePicker/components/disableDateRange';

const ShopifyWidget = (props) => {
  const { data, index, movingButton, height, configuration, load_chart } = props;

  return (
    <Widget
      bodyStyle={{
        height: height - 60,
        overflowX: 'auto',
        padding: 20,
      }}
      className='h-full'
      widget_key={'shopify_widget' + index}
      integrationType='shopify'
      title={data.title}
      index={index}
      disableLimit
      disableStatusFilter
      disableAccountsFilter
      showIntegrationFilters
      disableLevelFilter
      disabledDate={disabled7DaysDate}
      movingButton={movingButton}
      data={data}
      default_filter={data?.defaultFilter}
      configuration={configuration}
      showWidgetTypeSwitcher
      load_chart={load_chart}
      icon={<img src='/icons/integrations/shopify.svg' className='w-5 h-5 ml-2' />}
    >
      <ShopifyBody widget_key={'shopify_widget' + index} />
    </Widget>
  );
};

export default ShopifyWidget;

ShopifyWidget.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  movingButton: PropTypes.element.isRequired,
  height: PropTypes.number.isRequired,
};
