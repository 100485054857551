import React from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useNetworksReportsQuery } from 'store/redux/apis/dashboard.api';
import Widget from 'components/Widget/Widget';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import NetworkCard from 'components/widgets/components/NetworkWidget';

const NetworksData = ({ movingButton, height, data, configuration, index }) => {
  const { date_from, date_to, date_range_key } = useWidgetDate('google_widget' + index);

  const filters = {
    date_from,
    date_to,
    date_range_key,
    ...data.defaultFilter,
  };

  const { data: google = {}, isLoading } = useNetworksReportsQuery(filters);

  return (
    <Widget
      title={data.title}
      icon={<img src='/icons/networks/google.svg' className='w-5 h-5 ' />}
      bodyStyle={{
        height: height - 60,
      }}
      movingButton={movingButton}
      configuration={configuration}
      disableLimit
      widget_key={'google_widget' + index}
      disableStatusFilter
      disableLevelFilter
      network='google'
      default_filter={data?.defaultFilter}
      networkSwitch={false}
      index={index}
      adsFilter
      adsetFilter
      campaignFilter
      disableDateFilter
      showDatePicker
    >
      <Skeleton loading={isLoading} active={true}>
        <NetworkCard data={google?.google} network='google' />
      </Skeleton>
    </Widget>
  );
};

export default NetworksData;

NetworksData.propTypes = {
  movingButton: PropTypes.element.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
