import React from 'react';
import { mdiInformation } from '@mdi/js';
import { Popover } from 'antd';
import Icon from '@mdi/react';
import GrossIncomeInfo from 'components/ReportInfo/GrossIncomeInfo';
import GrossProfitInfo from 'components/ReportInfo/GrossProfitInfo';
import { useGetReportInfoMutation } from 'modules/report/api/report.api';
import NetProfitInfo from 'components/ReportInfo/NetProfitInfo';
import AdSpendInfo from 'components/ReportInfo/AdSpendInfo';

const ReportInfo = (props) => {
  const { icon = mdiInformation, trigger = 'click', type = null, iconProps, info = null } = props;

  const [fetchData, result] = useGetReportInfoMutation();
  const { isLoading, data } = result;

  const handleOpenChange = (open) => {
    if (open) {
      fetchData({ ...info, type: type });
    }
  };

  const Component = Contents[type];

  return (
    <Popover
      onOpenChange={handleOpenChange}
      content={Component ? <Component isLoading={isLoading} data={data} /> : 'No Data'}
      title={props.title}
      trigger={trigger}
    >
      {icon && <Icon path={icon} {...iconProps} className='cursor-pointer' />}
    </Popover>
  );
};

const Contents = {
  gross_income: GrossIncomeInfo,
  gross_profit: GrossProfitInfo,
  net_profit: NetProfitInfo,
  ad_spent: AdSpendInfo,
};

export default ReportInfo;
