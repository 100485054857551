import React from 'react';
import Icon from '@mdi/react';
import {
  mdiAccountCash,
  mdiAccountPlus,
  mdiCellphoneArrowDown,
  mdiCloseBox,
  mdiCursorDefaultClickOutline,
  mdiFileCheck,
  mdiForumOutline,
  mdiImage,
  mdiSearchWeb,
  mdiShopping,
  mdiThumbUp,
  mdiVideoImage,
  mdiRocketLaunch,
  mdiWebBox,
  mdiCloudUpload,
  mdiBullhorn,
} from '@mdi/js';
import { Tooltip } from 'antd';
import Ninja from '@ninja';

const typeToIcon = {
  search: mdiSearchWeb,
  display: mdiImage,
  traffic: mdiCursorDefaultClickOutline,
  link_clicks: mdiCursorDefaultClickOutline,
  post_engagment: mdiForumOutline,
  lead_generation: mdiAccountPlus,
  shopping: mdiShopping,
  page_likes: mdiThumbUp,
  video: mdiVideoImage,
  conversions: mdiFileCheck,
  app_installs: mdiCellphoneArrowDown,
  app: mdiCellphoneArrowDown,
  outcome_leads: mdiAccountCash,
  none: mdiCloseBox,
  p_max: mdiRocketLaunch,
  webpage: mdiWebBox,
  upload_clicks: mdiCloudUpload,
  regular_campaign: mdiBullhorn,
};

const IconTypeFormatter = ({ row, ...rest }) => {
  if (!row.type) {
    return null;
  }
  return (
    <Tooltip title={row.type.toLowerCase()}>
      <Icon
        path={typeToIcon[row.type.toLowerCase()] ?? ''}
        className='p-0 m-0'
        color={Ninja.colors.light}
        {...rest}
      />
    </Tooltip>
  );
};

export default IconTypeFormatter;
