import React from 'react';
import Widget from 'components/Widget/Widget';
import ShopifyChartContent from './components/ShopifyChartContent';
import { metricsList as defaultMetricsList } from '../components/metricsList';
import { disabled7DaysDate } from 'components/DateRangePicker/components/disableDateRange';

const ShopifyReportsChart = (props) => {
  const { data, index, movingButton, height, configuration } = props;

  const metricsList = {
    avg_order_value: { color: '#3B6F3A', name: 'Avg. Order Value', currency: true },
    cac: { color: '#5A8F53', name: 'CAC', currency: true },
    avg_profit_per_order: { color: '#7A9E5E', name: 'Avg. Profit Per Order', currency: true },
    new_customer_orders: { color: '#A0C68F', name: 'New Customer Orders' },
    orders: { color: 'rgb(145, 199, 22)', name: 'Orders' },
    cogs: { color: '#9DC08B', name: 'COGS', currency: true },
  };

  return (
    <Widget
      className='h-full'
      widget_key={'shopify_widget' + index}
      integrationType='shopify'
      title={data.title}
      index={index}
      disableLimit
      disableStatusFilter
      disableAccountsFilter
      showIntegrationFilters
      disableLevelFilter
      disabledDate={disabled7DaysDate}
      movingButton={movingButton}
      data={data}
      default_filter={data?.defaultFilter}
      configuration={configuration}
      icon={<img src='/icons/integrations/shopify.svg' className='w-7 h-7 ml-2' />}
      metricsList={{ ...defaultMetricsList, ...metricsList }}
      load_chart={true}
    >
      <ShopifyChartContent height={height - 50} widget_key={'shopify_widget' + index} />
    </Widget>
  );
};

export default ShopifyReportsChart;
