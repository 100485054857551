import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Checkbox from 'components/form/fields/Checkbox';
import React from 'react';

const FilePreview = (props) => {
  const classes = useStyles();
  const {
    checkable = false,
    checked = false,
    type = 'image',
    onClick,
    id,
    name,
    thumbnail,
    className,
  } = props;

  const handleClick = () => {
    onClick(id);
  };

  const types = {
    image: () => {
      return (
        <div
          className={clsx(className, classes.fileHolder, {
            [classes.checkedDiv]: checked,
          })}
          onClick={handleClick}
        >
          {checkable && <Checkbox checked={checked} className={classes.checkbox} />}
          <img style={{ width: 'calc(100% - 10px)' }} src={thumbnail} alt='' />
        </div>
      );
    },
    video: () => {
      return (
        <div
          className={clsx(className, classes.fileHolder, {
            [classes.checkedDiv]: checked,
          })}
          onClick={handleClick}
        >
          {checkable && <Checkbox checked={checked} className={classes.checkbox} />}
          <video style={{ width: 'calc(100% - 10px)' }} controls='true' src={thumbnail}></video>
        </div>
      );
    },
  };

  return (
    <div className={clsx(classes.container, className)}>
      {types[type] ? types[type]() : null}
      <Typography className={classes.name} variant='body1'>
        {name}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  name: {
    width: '100%',
    overflow: 'hidden',
    marginTop: '5px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  container: {
    textAlign: 'center',
    overflow: 'hidden',
    height: '205px',
  },
  fileHolder: {
    position: 'relative',
    width: '100%',
    height: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#e3e3e382',
    borderRadius: '6px',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    transition: '100ms all ease-in-out',
    overflow: 'hidden',
    padding: '3px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'inset 0px 0px 0px 3px #FF4E00',
    },
    '&:hover $checkbox': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  checkedDiv: {
    boxShadow: 'inset 0px 0px 0px 3px #FF4E00',
    transform: 'scale(0.97)',
    background: '#FF4E00',
  },
  checkbox: {
    color: theme.palette.secondary.main,
    '&:not(.Mui-checked)': {
      visibility: 'hidden',
      opacity: 0,
    },
    '&.Mui-checked .MuiIconButton-label': {
      borderRadius: 'inherit',
      background: theme.palette.custom.white,
    },
    position: 'absolute',
    transition: '100ms all ease-in-out',
    right: 8,
    top: 8,
  },
}));

export default FilePreview;
