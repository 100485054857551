import { Card, Skeleton } from 'antd';
import ComparableStatistic from 'components/Statistic/ComparableStatistic';
import React, { useMemo } from 'react';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';

const NetworkAccountStatisticsCards = (props) => {
  const { accountId, filters } = props;
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({
    id: accountId,
    filters,
  });

  const statistics = [
    { title: 'Ad Spent', currency: true, value: account?.report?.ad_spent },
    { title: 'Clicks', value: account?.report?.clicks },
    { title: 'Impressions', value: account?.report?.impressions },
    { title: 'ROI', prefix: '%', value: account?.report?.roi },
    { title: 'ROAS', value: account?.report?.roas },
    { title: 'CPA', currency: true, value: account?.report?.cpa },
    { title: 'CPC', currency: true, value: account?.report?.cpc },
  ];

  return (
    <Skeleton loading={isFetching} active>
      <div className='grid grid-cols-3 gap-2'>
        {statistics.map(
          (stat, index) =>
            stat.value != null && (
              <Card key={index} bordered={false} className='col-span-2 md:col-span-1'>
                <ComparableStatistic
                  title={stat.title}
                  currency={stat.currency}
                  prefix={stat.prefix}
                  current={stat.value}
                />
              </Card>
            )
        )}
      </div>
    </Skeleton>
  );
};

export default NetworkAccountStatisticsCards;
