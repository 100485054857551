import React, { useEffect } from 'react';
import { useWidget } from 'components/Widget/useWidget';
import IntegrationWidget from 'components/widgets/components/IntegrationWidget';
import ninjaApi from 'store/redux/apis';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { getMin7DaysRange } from 'components/DateRangePicker/components/disableDateRange';

const ShopifyBody = ({ widget_key }) => {
  const [getIntegrationsReports, { data: shopify = [], isLoading }] =
    ninjaApi.useLazyIntegrationsReportsQuery();
  const { filters, chartConfig } = useWidget();
  const { date_from, date_to, setDate } = useWidgetDate(widget_key);

  useEffect(() => {
    let adjustedDateFrom = date_from;
    let adjustedDateTo = date_to;

    if (chartConfig?.load_chart) {
      ({ date_from: adjustedDateFrom, date_to: adjustedDateTo } = getMin7DaysRange(
        date_from,
        date_to
      ));
    }

    const params = {
      ...filters,
      integration: 'shopify',
      load_chart: chartConfig?.load_chart,
      date_from: adjustedDateFrom,
      date_to: adjustedDateTo,
    };

    if (chartConfig?.load_chart) {
      params.group_by = 'date';
    }

    if (filters?.date_from) {
      getIntegrationsReports(params)
        .unwrap()
        .then(() => {
          setDate({
            date_from: adjustedDateFrom,
            date_to: adjustedDateTo,
            date_key: widget_key,
          });
        });
    }
  }, [filters, chartConfig?.load_chart, date_to]);

  return (
    <div className='w-full'>
      <IntegrationWidget
        totals={shopify.totals}
        loading={isLoading}
        data={shopify}
        integration='shopify'
        widget_key={widget_key}
      />
    </div>
  );
};

export default ShopifyBody;
