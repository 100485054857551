import React, { useEffect } from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useWidget } from 'components/Widget/useWidget';
import ninjaApi from 'store/redux/apis/ninja.api';
import ChartLoading from 'components/charts/components/ChartLoading';
import NoResultFound from 'components/charts/components/NoResultFound';
import ChartBody from 'modules/DashboardModule/charts/components/ChartBody';
import dayjs from 'dayjs';
import { getMin7DaysRange } from 'components/DateRangePicker/components/disableDateRange';

const metricsList = {
  avg_profit_per_order: { color: '#35224C', name: 'Avg. Profit Per Order', currency: true },
  new_customer_orders: { color: '#4E326F', name: 'New Customer Orders' },
  orders: { color: '#664193', name: 'Orders' },
  cogs: { color: '#9977C3', name: 'COGS', currency: true },
  avg_order_value: { color: '#B49BD2', name: 'Avg. Order Value', currency: true },
  cac: { color: '#CEBEE2', name: 'CAC', currency: true },
  // refund_rate: { color: '#B4782A', name: 'Refund Rate', symbol: '%' },
  // quantity: { color: '#D2B79F', name: 'Unit Sold', symbol: '%' },
};

const WooCommerceChartContent = ({ height }) => {
  const [getIntegrationsReports, { data: data = [], isLoading }] =
    ninjaApi.useLazyIntegrationsReportsQuery();
  const { filters, chartConfig, widget_key } = useWidget();
  const { date_from, date_to, setDate } = useWidgetDate(widget_key);

  useEffect(() => {
    let adjustedDateFrom = date_from;
    let adjustedDateTo = date_to;

    ({ date_from: adjustedDateFrom, date_to: adjustedDateTo } = getMin7DaysRange(
      date_from,
      date_to
    ));

    const params = {
      ...filters,
      integration: 'woocommerce',
      load_chart: chartConfig?.load_chart,
      date_from: adjustedDateFrom,
      date_to: adjustedDateTo,
    };

    if (chartConfig?.load_chart) {
      params.group_by = 'date';
    }

    if (filters?.date_from) {
      getIntegrationsReports(params)
        .unwrap()
        .then(() => {
          setDate({
            date_from: adjustedDateFrom,
            date_to: adjustedDateTo,
            date_key: widget_key,
          });
        });
    }
  }, [filters, chartConfig?.load_chart, date_to]);

  if (isLoading) {
    return <ChartLoading height={height} />;
  }

  if (!data?.data?.length) {
    return <NoResultFound height={height} />;
  }

  return <ChartBody height={height} custommetricsList={metricsList} data={data.data} />;
};

export default WooCommerceChartContent;
