import React, { Fragment } from 'react';
import { DateFormatter } from 'components/charts/formatters';
import { useWidget } from 'components/Widget/useWidget';
import getFormattedNumber from 'store/redux/utils/getFormattedNumber';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import { metricsList as defaultList } from 'modules/DashboardModule/charts/components/metricsList';

export const MetricItem = ({ entry, metricsList }) => {
  const { name, color, symbol, currency } = metricsList[entry.dataKey] || {};

  let formattedValue = entry.value;
  if (symbol) {
    formattedValue = `${getFormattedNumber(entry.value)}${symbol}`;
  } else if (currency) {
    formattedValue = getFormattedValue(entry.value);
  }

  const { valueFormatter, formatter } = defaultList[entry?.dataKey] || {};
  const ValueFormatter = valueFormatter || Fragment;
  const FormatterComponent = formatter || Fragment;

  return (
    <div className='flex items-center w-full gap-6' key={entry.dataKey}>
      <div className='flex items-center w-full gap-1'>
        {color && <div className='rounded-sm h-3 w-3' style={{ background: color }} />}
        <span className='text-md font-normal'>{name}</span>
      </div>
      <span>
        {valueFormatter ? (
          <ValueFormatter value={entry?.value} />
        ) : formatter ? (
          <FormatterComponent row={entry.payload} fontSize={14} />
        ) : (
          formattedValue
        )}
      </span>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label, metricsList, dataKey = 'date' }) => {
  const { filters } = useWidget();

  if (!active || !payload?.length) return null;

  const formattedDate = DateFormatter({ period: filters?.group_by || 'days', date: label });

  const gridColsClass = payload.length > 8 ? 'grid-cols-2' : 'grid-cols-1';

  return (
    <div
      className='p-2 rounded shadow-xl gap-2 grid'
      style={{ background: 'rgba(255,255,255,0.95' }}
    >
      <div className='font-semibold text-md'>{dataKey === 'date' ? formattedDate : label}</div>
      <div className={`grid w-full gap-x-4 ${gridColsClass}`}>
        {payload.map((entry) => {
          return <MetricItem metricsList={metricsList} entry={entry} />;
        })}
      </div>
    </div>
  );
};

export default CustomTooltip;
