import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import dayjs from 'dayjs';
import { mdiCalendarMonth } from '@mdi/js';
import Icon from '@mdi/react';
import './style.css';

const DatePicker = (props) => {
  const { value, onChange, size = 'large', placeholder = 'Select Date', ...rest } = props;

  const onRangeChange = (date) => {
    if (date) {
      onChange(date.format('YYYY-MM-DD'));
    }
  };

  const formattedValue = value ? (dayjs(value).isValid() ? dayjs(value) : null) : null;

  return (
    <AntdDatePicker
      value={formattedValue}
      onChange={onRangeChange}
      needConfirm
      size={size}
      placeholder={placeholder}
      suffixIcon={<Icon className='text-orange' path={mdiCalendarMonth} />}
      {...rest}
    />
  );
};

export default DatePicker;
