import React from 'react';
import NinjaTable from 'components/tables/NinjaTable';
import ConversionFormatter from 'components/table-formatters/conversions/ConversionFormatter';
import { IconTypeFormatter } from 'components/tables/NinjaTable/formatters';

const list = [
  {
    name: 'id',
    key: 'id',
  },
  {
    name: 'Name',
    key: 'name',
    resizable: true,
    minWidth: 250,
    renderCell: ConversionFormatter,
  },
  {
    name: 'Type',
    key: 'source',
    resizable: true,
    renderCell: ({ row }) => {
      return row?.type && <IconTypeFormatter row={row} />;
    },
  },
];

const NetworkConversionsTable = (props) => {
  return (
    <NinjaTable
      title='Conversions'
      dataProvider='conversionsList'
      columns={list}
      sortableColumns
      {...props}
    />
  );
};

export default NetworkConversionsTable;
