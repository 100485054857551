import React from 'react';
import Widget from 'components/Widget/Widget';
import Networks from './component/Networks';

const NetworksChart = ({ height, movingButton, data, index }) => {
  const metricOptions = [
    { value: 'gross_income', label: 'Gross Income' },
    { value: 'gross_profit', label: 'Gross Profit' },
    { value: 'net_profit', label: 'Net Profit' },
    { value: 'ad_spent', label: 'Ad Spent' },
    { value: 'roi', label: 'ROI' },
    { value: 'roas', label: 'ROAS' },
  ];

  return (
    <Widget
      tableWidget
      widget_key={'networks' + index}
      title={data.title}
      index={index}
      disableLevelFilter
      disableLimit
      disableStatusFilter
      movingButton={movingButton}
      default_filter={data.defaultFilter}
      metricOptions={metricOptions}
      disableAccountsFilter
    >
      <Networks height={height - 50} metricOptions={metricOptions} />
    </Widget>
  );
};

export default NetworksChart;
