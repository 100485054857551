import React from 'react';
import Widget from 'components/Widget/Widget';
import PnLByDaysChartBody from './components/PnLByDaysChartBody';
import { disabled7DaysDate } from 'components/DateRangePicker/components/disableDateRange';
import { metricsList } from '../components/metricsList';

const PnLByDaysChart = ({ height, movingButton, data, index, configuration }) => {
  return (
    <Widget
      className='w-full bg-white'
      widget_key={'PnLByDaysChart' + index}
      title={data.title}
      index={index}
      disableLevelFilter
      disableLimit
      disableStatusFilter
      movingButton={movingButton}
      default_filter={data.defaultFilter}
      configuration={configuration}
      metricsList={metricsList}
      disabledDate={disabled7DaysDate}
      data={data}
      load_chart={true}
      adsFilter
      adsetFilter
      campaignFilter
    >
      <PnLByDaysChartBody height={height - 50} />
    </Widget>
  );
};

export default PnLByDaysChart;
