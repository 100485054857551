import { Hidden, LinearProgress } from '@material-ui/core';
import { Content, Sidebar, Header } from 'components/layouts/components';
import FullPageOverlay from 'components/Loaders/FullPageOverlay/FullPageOverlay';
import React, { Suspense } from 'react';
import { useLoader } from 'store/redux/states/ui/ui.hooks';
import Alerts from './Alerts';
import SecondaryMenu from 'components/layouts/AppLayout/SecondaryMenu';
import DateRangePicker from 'components/DateRangePicker';

const AppLayout = (props) => {
  const [loading] = useLoader('global');

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <FullPageOverlay loading={loading} />
      <Hidden smUp implementation='js'>
        <Sidebar variant='temporary' />
      </Hidden>
      <Hidden xsDown implementation='js'>
        <Sidebar />
      </Hidden>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
        <Header />
        <SecondaryMenu
          component={
            <DateRangePicker
              placement='bottomRight'
              size='middle'
              formatter='yyyy-MM-dd'
              changeGlobal={true}
              placeholder={['Date from', 'Date to']}
              date_key='global'
            />
          }
        />
        <Content>
          <Alerts />
          <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
        </Content>
      </div>
    </div>
  );
};

export default AppLayout;
