import React from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import getFormattedNumber from 'store/redux/utils/getFormattedNumber';
import Dot from './Dot';

export default function DotDigitFormatter(props) {
  let val = 0,
    state = null;

  // Get column value
  if (props.getValue) {
    val = props.getValue(props);
  } else {
    val = props.row[props.column.key];
  }

  if (val === null) {
    val = 0;
  }

  // Get column state
  if (props.column.type === 'profit') {
    state = val > 0 ? 'success' : 'error';
  } else if (props.column.type === 'spent') {
    state = val > 0 ? 'error' : 'success';
  } else if (props.column.type === 'income') {
    state = val > 0 ? 'success' : 'error';
  }

  // Format value
  if (props.column.currency) {
    val = getFormattedValue(val);
  } else {
    val = getFormattedNumber(val);
  }

  return (
    <div className='flex whitespace-nowrap items-center'>
      {val !== '' && <Dot state={state} />}
      <span>{val}</span>
    </div>
  );
}