import React, { useMemo, useState } from 'react';
import { mdiFilter, mdiFilterOff, mdiOpenInNew, mdiInformation } from '@mdi/js';
import useQueryParams from 'hooks/useQueryParams';
import Ninja from '@ninja';
import P from 'router/paths';
import PropTypes from 'prop-types';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import { useHistory } from 'react-router-dom';
import Icon from '@mdi/react';
import { Badge, Flex, Dropdown, Button } from 'antd';

const DealFormatter = ({ row, param }) => {
  const { setUrlParams, searchParams } = useQueryParams();
  const history = useHistory();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleFilterDeals = () => {
    history.push(P.CRM.DEALS);
    setUrlParams({ contact_ids: row.id });
  };

  const handleDropdownToggle = () => {
    setDropdownVisible((prev) => !prev);
  };

  const items = useMemo(() => {
    if (!row?.deal_ids?.length) {
      return [{ label: 'No deals found', key: 'no-deals', disabled: true }];
    }

    return row.deal_ids.map((item) => ({
      label: (
        <div
          onClick={() => {
            setUrlParams({ [param]: item });
            setDropdownVisible(false);
          }}
          className='flex flex-center justify-between gap-4 text-xs cursor-pointer'
        >
          Deal ID - {item}
          <Icon size={0.6} path={mdiOpenInNew} className='text-blue' />
        </div>
      ),
      key: item,
    }));
  }, [row, setUrlParams, param]);

  return (
    <div className='flex items-center justify-center w-full'>
      {row.deals_count === 0 ? (
        <Icon path={mdiFilterOff} color={Ninja.colors.xlight} />
      ) : (
        <Flex align='center' justify='center' gap={5}>
          <Button
            shape='circle'
            type='text'
            icon={
              <Badge
                overflowCount={5}
                count={row.deals_count}
                color={Ninja.colors.success}
                size='small'
                onClick={handleFilterDeals}
              >
                <Icon path={mdiFilter} color={Ninja.colors.light} size={0.8} />
              </Badge>
            }
          />
          <TableIcons
            color={Ninja.colors.light}
            icons={[
              {
                icon: mdiInformation,
                onClick: handleDropdownToggle,
                tooltip: 'Deal Details',
                size: 0.8,
                color: row.deal_ids.includes(Number(searchParams[param]))
                  ? Ninja.colors.orange
                  : Ninja.colors.light,
              },
            ]}
          />
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            open={dropdownVisible}
            onOpenChange={setDropdownVisible}
          />
        </Flex>
      )}
    </div>
  );
};

export default DealFormatter;

DealFormatter.propTypes = {
  row: PropTypes.object.isRequired,
  param: PropTypes.string.isRequired,
};
