import React from 'react';
import Widget from 'components/Widget/Widget';
import TikTokChartContent from './components/TikTokChartContent';
import { metricsList } from '../components/metricsList';
import { disabled7DaysDate } from 'components/DateRangePicker/components/disableDateRange';

const TikTokReportsChart = (props) => {
  const { data, index, movingButton, height, configuration } = props;

  return (
    <Widget
      className='h-full'
      widget_key={'tiktok_chart' + index}
      title={data.title}
      index={index}
      disableLimit
      disableStatusFilter
      disableLevelFilter
      movingButton={movingButton}
      data={data}
      default_filter={data?.defaultFilter}
      icon={<img src='/icons/networks/tiktok.svg' className='w-7 h-7 ' />}
      networkSwitch={false}
      network='tiktok'
      metricsList={metricsList}
      configuration={configuration}
      disabledDate={disabled7DaysDate}
      load_chart={true}
      adsFilter
      adsetFilter
      campaignFilter
    >
      <TikTokChartContent height={height - 50} />
    </Widget>
  );
};

export default TikTokReportsChart;
