import React, { useMemo } from 'react';
import { Select, Space } from 'antd';
import PropTypes from 'prop-types';

const IntegrationsSelect = ({ placeholder = 'Select Integration', ...rest }) => {
  const options = useMemo(
    () => [
      { value: 'woocommerce', label: 'Woocommerce', icon: '/icons/integrations/woocommerce.svg' },
      { value: 'shopify', label: 'Shopify', icon: '/icons/integrations/shopify.svg' },
    ],
    []
  );

  return (
    <Select
      mode='multiple'
      style={{ width: '100%' }}
      placeholder={placeholder}
      maxTagCount='responsive'
      allowClear
      {...rest}
    >
      {options.map(({ value, label, icon }) => (
        <Select.Option key={value} value={value} label={label}>
          <Space>
            <img src={icon} alt={label} className='w-3 h-3' />
            {label}
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

IntegrationsSelect.propTypes = {
  placeholder: PropTypes.string,
};

export default IntegrationsSelect;
