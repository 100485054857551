import React, { Fragment, useEffect } from 'react';
import { Button, Form, Input, Modal, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import {
  useGetNetworkAccountQuery,
  useStartAutomaticTrackingMutation,
} from 'store/redux/apis/network-accounts/network-accounts.api';
import { snackbar } from 'components/notifications/notifications';

const NetworkAccountTrackingAutomaticSetupForm = (props) => {
  const { accountID } = props;
  const [form] = Form.useForm();
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({ id: parseInt(accountID) });
  const [startAutoTrackingQuery, { isLoading: isUpdating }] = useStartAutomaticTrackingMutation();
  const formInitialValues = {
    id: '',
    tracking_url_parameters: '',
    final_url_suffix: '',
  };

  const onSuccess = (data) => {
    form.resetFields();
    props.onSuccess?.(data);
  };

  const submitForm = (data) => {
    if (account?.is_tracked) {
      return snackbar.error('Attribution is already set up for this account.');
    }
    snackbar
      .load(
        () =>
          startAutoTrackingQuery({
            account_id: account.id,
          }).unwrap(),
        {
          loadingMessage: 'Setting up automatic attribution',
          successMessage: 'Attribution set up successfully',
          errorMessage: 'Failed to set up attribution',
          hideDuration: 2,
        }
      )
      .then(onSuccess);
  };

  useEffect(() => {
    if (!account?.id) return;
    form.setFieldsValue(account);
  }, [account]);

  const isGoogle = account?.network === 'google';

  return (
    <Skeleton loading={isFetching}>
      {isGoogle ? (
        <Form
          form={form}
          disabled={isFetching || isUpdating}
          name='layout-multiple-horizontal'
          layout='vertical'
          initialValues={formInitialValues}
          onFinish={submitForm}
          autoComplete='off'
        >
          <Form.Item layout='vertical' name='id' hidden />
          <Form.Item
            layout='vertical'
            label='Tracking URL Parameters'
            name='tracking_url_parameters'
            extra='Learn more about tracking parameters'
          >
            <Input disabled readOnly />
          </Form.Item>
          <Form.Item
            layout='vertical'
            label='Final URL Suffix'
            name='final_url_suffix'
            tooltip={{
              title: 'How tracking works',
            }}
          >
            <Input disabled readOnly />
          </Form.Item>
          <Form.Item className='flex-center pt-4'>
            <Button
              disabled={Boolean(account?.is_tracked)}
              loading={isFetching}
              type='primary'
              htmlType='submit'
            >
              Set Up Automatic Tracking
            </Button>
          </Form.Item>
        </Form>
      ) : (
        // For facebook and other networks
        <Fragment>
          <Button
            disabled={Boolean(account?.is_tracked)}
            loading={isFetching}
            type='primary'
            onClick={() => {
              Modal.confirm({
                title: 'Wait!',
                onOk: submitForm,
                okType: 'primary',
                okButtonProps: {
                  type: 'primary',
                },
                content: (
                  <div>
                    Setting up automatic tracking on Meta ads manager account will edit the ad
                    settings which can cause temporary disruption in ad delivery. Are you sure you
                    want to proceed?
                  </div>
                ),
                footer: (_, { OkBtn, CancelBtn }) => (
                  <div className='flex w-full justify-end'>
                    <CancelBtn />
                    <OkBtn />
                  </div>
                ),
              });
            }}
            htmlType='submit'
          >
            Set Up Automatic Tracking
          </Button>
        </Fragment>
      )}
    </Skeleton>
  );
};

NetworkAccountTrackingAutomaticSetupForm.propTypes = {
  accountID: PropTypes.number.isRequired,
};

export default NetworkAccountTrackingAutomaticSetupForm;
