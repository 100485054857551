import React from 'react';
import Widget from 'components/Widget/Widget';
import BingChartContent from './components/BingChartContent';
import { metricsList } from '../components/metricsList';
import { disabled7DaysDate } from 'components/DateRangePicker/components/disableDateRange';

const BingReportsChart = (props) => {
  const { data, index, movingButton, height, configuration } = props;

  return (
    <Widget
      className='h-full'
      widget_key={'bing_chart' + index}
      title={data.title}
      index={index}
      disableLimit
      disableStatusFilter
      disableLevelFilter
      movingButton={movingButton}
      data={data}
      default_filter={data?.defaultFilter}
      icon={<img src='/icons/networks/bing.svg' className='w-7 h-7 ' />}
      networkSwitch={false}
      configuration={configuration}
      network='bing'
      metricsList={metricsList}
      disabledDate={disabled7DaysDate}
      load_chart={true}
      adsFilter
      adsetFilter
      campaignFilter
    >
      <BingChartContent height={height - 50} />
    </Widget>
  );
};

export default BingReportsChart;
