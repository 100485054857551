import React, { useState } from 'react';
import { Popover, Flex, Button, Radio } from 'antd';
import { makeStyles } from '@material-ui/core';
import { mdiCogOutline, mdiLink } from '@mdi/js';
import Icon from '@mdi/react';
import TableColumnCustomization from 'components/tables/NinjaTable/TableColumnCustomization';
import SavedFilter from 'components/tables/NinjaTable/SavedFilter';
import { AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { mdiFilterOutline, mdiTune, mdiFileTree } from '@mdi/js';
import Ninja from '@ninja';

const WidgetCustomize = (props) => {
  const classes = useStyles();
  const {
    customizationId,
    changeLimit,
    onCustomizationUpdate,
    exportData = false,
    //
    customizationEdit,
    removeColumns,
    savedFilters = false,
    defaultPerPage,
    disableLimit,
    copyUrl = false,
    renderFilter,
    handleDelete,
    deleteLoading,
    default_filter,

    disableLevelFilter,
    changeLevel,
    options,
    filters,
    ...rest
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleCopy = async () => {
    setIsLoading(true);
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Popover
      placement='bottomLeft'
      mouseLeaveDelay={0.7}
      content={
        <div className={classes.root} style={{ minWidth: 150 }}>
          {savedFilters && (
            <Accordion
              expanded={expanded === 'savedFilters'}
              onChange={handleChange('savedFilters')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='flex items-center gap-1'
                aria-controls='savedFilters-content'
                id='savedFilters-header'
              >
                <Icon path={mdiFilterOutline} className='text-background-dark' size={0.6} />
                <span className='!text-sm  text-background-dark'>Saved Filters</span>
              </AccordionSummary>
              <AccordionDetails>
                <SavedFilter />
              </AccordionDetails>
            </Accordion>
          )}
          {!disableLevelFilter && (
            <div className='grid gap-2 mt-2'>
              <Flex align='center' gap='small'>
                <Icon path={mdiFileTree} className='text-background-dark' size={0.6} />
                <span className='!text-sm  text-background-dark'>Change Level</span>
              </Flex>
              <Radio.Group
                optionType='button'
                buttonStyle='solid'
                value={filters?.level}
                onChange={changeLevel}
                options={[
                  { label: 'Adsets', value: 'adset' },
                  { label: 'Campaigns', value: 'campaign' },
                  { label: 'Ads', value: 'ad' },
                ]}
              />
            </div>
          )}
          {customizationId && (
            <Accordion expanded={expanded === 'columns'} onChange={handleChange('columns')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className='flex items-center gap-1'
                aria-controls='columns-content'
                id='columns-header'
              >
                <Icon path={mdiTune} className='text-background-dark' size={0.6} />
                <span className='!text-sm  text-background-dark'>Columns</span>
              </AccordionSummary>
              <AccordionDetails>
                <TableColumnCustomization
                  customizationId={customizationId}
                  onCustomizationUpdate={onCustomizationUpdate}
                  customizationEdit={customizationEdit}
                  removeColumns={removeColumns}
                />
              </AccordionDetails>
            </Accordion>
          )}

          {copyUrl && (
            <>
              <span className={classes.line} />
              <AccordionSummary className='flex items-center gap-1' onClick={handleCopy}>
                <Icon path={mdiLink} className='!w-4 !h-4 text-background-dark' size={0.6} />
                <span className='font-normal'>{isLoading ? 'copied!' : 'Copy Link'}</span>
              </AccordionSummary>
            </>
          )}
        </div>
      }
    >
      <Button
        type='text'
        size='large'
        icon={<Icon path={mdiCogOutline} color={Ninja.colors.darkText} size={1} />}
      />
    </Popover>
  );
};

export default WidgetCustomize;

const useStyles = makeStyles((theme) => ({
  line: {
    borderTop: '1px solid lightgrey',
    height: 1,
    width: '100%',
    margin: '5px 0px',
  },
  root: {
    width: 'fit-content',
    display: 'grid',
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px 5px',
      margin: '0px',
      height: '35px',
      minHeight: '35px',
    },
    '& .MuiAccordionSummary-root:hover': {
      backgroundColor: '#d5d5d542 !important',
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      gap: '10px',
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      height: '35px',
      minHeight: '35px',
      backgroundColor: '#d5d5d542 !important',
    },
    ' & .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiCollapse-root': {
      width: '100%',
      padding: '0px 10px',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      margin: '10px 0px',
    },
  },
}));
