import React from 'react';
import {
  RoiFormatter,
  DotDigitFormatter,
  JustValueFormatter,
  RoasFormatter,
} from 'components/tables/NinjaTable/formatters';
import { RoiDefinition, RoasDefinition } from 'components/NameDefinition';

const WidgetsCommonColumns = () => [
  {
    name: 'Ad Spent',
    key: 'ad_spent',
    type: 'spent',
    renderCell: JustValueFormatter,
    renderSummaryCell: JustValueFormatter,
    sortable: true,
    resizable: true,
  },
  {
    name: 'Gross Income',
    key: 'gross_income',
    type: 'income',
    renderCell: JustValueFormatter,
    renderSummaryCell: JustValueFormatter,
    sortable: true,
    resizable: true,
  },
  {
    name: 'Gross Profit',
    key: 'gross_profit',
    type: 'profit',
    renderCell: JustValueFormatter,
    renderSummaryCell: JustValueFormatter,
    sortable: true,
    resizable: true,
  },
  {
    name: 'Net Profit',
    key: 'net_profit',
    type: 'profit',
    renderCell: DotDigitFormatter,
    renderSummaryCell: DotDigitFormatter,
    currency: true,
    sortable: true,
    resizable: true,
  },
  {
    name: <RoasDefinition />,
    key: 'roas',
    renderCell: RoasFormatter,
    renderSummaryCell: ({ row }) => {
      return row.roas && <RoasFormatter row={row} />;
    },
    resizable: true,
  },
  {
    name: <RoiDefinition />,
    key: 'roi',
    renderCell: RoiFormatter,
    renderSummaryCell: ({ row }) => {
      return row.roi && <RoiFormatter row={row} />;
    },
    resizable: true,
  },
];

export default WidgetsCommonColumns;
