import React from 'react';
import { SELECT_COLUMN_KEY } from 'react-data-grid';
import { Checkbox } from 'antd';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';

const SelectSingleRow = (props) => {
  const { selectRow, isRowSelected, deselectRow } = useNinjaTable();

  return (
    <Checkbox
      checked={isRowSelected(props.row)}
      onChange={(e) => {
        e.target.checked ? selectRow(props.row) : deselectRow(props.row);
      }}
    />
  );
};

const SelectAllRows = () => {
  const { selectAllRows, deselectAllRows, isAllRowsSelected, selectedRowsCount } = useNinjaTable();

  return (
    <Checkbox
      indeterminate={!isAllRowsSelected && selectedRowsCount > 0}
      checked={isAllRowsSelected}
      onChange={(e) => {
        e.target.checked ? selectAllRows() : deselectAllRows();
      }}
    />
  );
};

export const SelectColumn = {
  key: SELECT_COLUMN_KEY,
  name: '',
  cellClass: 'nnj-selector-cell',
  headerCellClass: 'nnj-selector-header',
  resizable: false,
  frozen: true,
  renderHeaderCell: SelectAllRows,
  renderCell: SelectSingleRow,
};
