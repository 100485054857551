import React from 'react';
import { Statistic } from 'antd';
import Ninja from '@ninja';
import Icon from '@mdi/react';
import { mdiMenuDown, mdiMenuUp } from '@mdi/js';

const RoiFormatter = ({ row: { roi } = {}, fontSize = 12, ...props }) => {
  const displayRoi = roi ?? 0;

  if (!displayRoi || displayRoi === 0)
    return (
      <Statistic
        value={0}
        suffix='%'
        valueStyle={{ fontSize: fontSize, display: 'flex', alignItems: 'center' }}
        {...props}
      />
    );

  return (
    <Statistic
      value={displayRoi}
      suffix='%'
      prefix={<Icon path={displayRoi > 30 ? mdiMenuUp : mdiMenuDown} size={1} />}
      valueStyle={{
        display: 'flex',
        alignItems: 'center',
        fontSize: fontSize,
        color: displayRoi > 30 ? Ninja.colors.green : Ninja.colors.red,
      }}
      {...props}
    />
  );
};

export default RoiFormatter;
