import Widget from 'components/Widget/Widget';
import NinjaTable from 'components/tables/NinjaTable';
import { DateFormatter } from 'components/tables/NinjaTable/formatters';
import React, { useMemo } from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import PropTypes from 'prop-types';
import WidgetsCommonColumns from 'components/widgets/components/WidgetsCommonColumns';

const ProfitsAndLossesByDays = ({ height, movingButton, data, index, configuration }) => {
  const { dates } = useWidgetDate('daily_statistics' + index);

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        key: 'date',
        width: 120,
        renderCell: DateFormatter,
        renderSummaryCell: () => {
          return 'Totals';
        },
      },
      ...WidgetsCommonColumns(),
    ],
    [dates]
  );

  return (
    <Widget
      tableWidget
      widget_key={'daily_statistics' + index}
      title={data.title}
      index={index}
      disableLevelFilter
      disableLimit
      disableStatusFilter
      movingButton={movingButton}
      default_filter={data.defaultFilter}
      configuration={configuration}
      adsFilter
      adsetFilter
      campaignFilter
      disableDateFilter
      showDatePicker
    >
      <NinjaTable
        columns={columns}
        dataProvider='dashboardDailyStats'
        queryParams={{ ...dates }}
        cardProps={{ disable: true }}
        maxHeight={height}
        footerProps={{ readOnlyPagination: true }}
        tableProps={{ template: 'widget' }}
      />
    </Widget>
  );
};

export default ProfitsAndLossesByDays;

ProfitsAndLossesByDays.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  movingButton: PropTypes.element.isRequired,
  height: PropTypes.number.isRequired,
};
