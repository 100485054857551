import React from 'react';
import NinjaTable from 'components/tables/NinjaTable';
import ValueFormatter from 'components/table-formatters/values/ValueFormatter';
import { CampaignFormatter } from 'components/tables/NinjaTable/formatters/sourceFormatters';
import { JustValueFormatter, SpentFormatter } from 'components/tables/NinjaTable/formatters';

const list = [
  {
    name: 'Name',
    key: 'name',
    resizable: true,
    sortable: true,
    maxWidth: 250,
    minWidth: 250,
    renderCell: ({ row }) => <CampaignFormatter editStatus={true} row={row} />,
    renderSummaryCell: () => 'Totals',
  },
  {
    name: 'Adsets',
    key: 'campaign.adsets_count',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'Ad Spent',
    key: 'ad_spent',
    sortable: true,
    renderCell: JustValueFormatter,
    renderSummaryCell: (data) => {
      return <SpentFormatter row={data.row} />;
    },
  },
  {
    name: 'Impressions',
    key: 'impressions',
    sortable: true,
    renderCell: ValueFormatter,
    renderSummaryCell: ValueFormatter,
  },
  {
    name: 'Clicks',
    key: 'clicks',
    sortable: true,
    renderCell: ValueFormatter,
    renderSummaryCell: ValueFormatter,
  },
  {
    name: 'CPA',
    key: 'cpa',
    sortable: true,
    renderCell: JustValueFormatter,
    renderSummaryCell: JustValueFormatter,
  },
  {
    name: 'CPC',
    key: 'cpc',
    sortable: true,
    renderSummaryCell: JustValueFormatter,
  },
];

const CampaignsTable = (props) => {
  return (
    <NinjaTable
      cardProps={{ disable: true }}
      title='Campaigns'
      dataProvider='campaignsList'
      columns={list}
      sortableColumns
      {...props}
    />
  );
};

export default CampaignsTable;
