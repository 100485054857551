import React from 'react';
import NameDefinition from './NameDefinition';
import PropTypes from 'prop-types';

const CpaDefinition = ({
  textFormat = 'text-xs font-semibold w-fit text-background-dark',
  definiton = 'Cost Per Acquisition',
  name = 'CPA',
  size,
}) => {
  return <NameDefinition name={name} definiton={definiton} textSize={textFormat} size={size} />;
};

export default CpaDefinition;

CpaDefinition.propTypes = {
  textFormat: PropTypes.string,
};
