import ninjaApi from 'store/redux/apis/ninja.api';
import { socketEvents } from 'utils/socket/socket-events';

window.socketEvents = socketEvents;

export const appsApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Shopify', 'Woocommerce', 'NetworkAccount', 'Integrations'],
  endpoints: (build) => ({
    disconnectIntegration: build.mutation({
      query: (data) => ({
        url: '/apps/integration/disconnect',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Shopify', 'Woocommerce', 'Integrations'],
    }),
    integrationList: build.query({
      query: (data) => ({
        url: '/integration/list',
        method: 'POST',
        data,
      }),
      providesTags: ['Integrations'],
      onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded, cacheEntryRemoved }) => {
        try {
          await cacheDataLoaded;

          const refreshIntegrations = () => {
            dispatch(appsApi.util.invalidateTags(['Integrations']));
          };

          socketEvents.on('shopify_store.connected', refreshIntegrations);

          await cacheEntryRemoved;
        } finally {
          socketEvents.off('shopify_store.connected', refreshIntegrations);
        }
      },
    }),
  }),
});

export const { useIntegrationListQuery, useDisconnectIntegrationMutation } = appsApi;
