import dayjs from 'dayjs';

export const resetTime = (date) => {
  return new Date(date?.getFullYear(), date?.getMonth(), date?.getDate());
};

export const staticRanges = [
  { key: 'today', label: 'Today', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
  {
    key: 'yesterday',
    label: 'Yesterday',
    value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')],
  },
  {
    key: 'this_week',
    label: 'This Week',
    value: [dayjs().startOf('week'), dayjs().endOf('week')],
  },
  {
    key: 'last_week',
    label: 'Last Week',
    value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
  },
  {
    key: 'this_month',
    label: 'This Month',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  },
  {
    key: 'last_month',
    label: 'Last Month',
    value: [
      dayjs().subtract(1, 'month').startOf('month'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
  },
];

export const getDateRangeByKey = (key) => {
  const rangeConfig = staticRanges.find((range) => range.key === key);
  if (rangeConfig) {
    const [startDate, endDate] = rangeConfig.value;

    return {
      label: rangeConfig.label,
      date_from: startDate.format('YYYY-MM-DD'),
      date_to: endDate.format('YYYY-MM-DD'),
    };
  }
  return { label: null, date_from: null, date_to: null };
};
