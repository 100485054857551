import clsx from 'clsx';
import React from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

const SpentFormatter = ({ row: { ad_spent } }) => {
  return (
    <span className='rounded-[14px] text-error font-medium'>
      {getFormattedValue(ad_spent)}
    </span>
  );
};

export default SpentFormatter;
