import React from 'react';
import { Flex, Button, Typography } from 'antd';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';

const ExportButton = () => {
  const { onExport } = useNinjaTable();

  return (
    <div className='w-52'>
      <Flex
        align='center'
        justify='space-between'
        gap='small'
        className='bg-background-light p-2 rounded-md'
      >
        <Flex gap='small' align='center'>
          <img src='/icons/export/excell.svg' className='w-6 h-6' />
          <Typography className='text-sm'>Excel</Typography>
        </Flex>
        <Button type='primary' size='small' onClick={onExport}>
          Download
        </Button>
      </Flex>
    </div>
  );
};

export default ExportButton;
