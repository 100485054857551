import React from 'react';
import { mdiInformation, mdiFilterOff } from '@mdi/js';
import useQueryParams from 'hooks/useQueryParams';
import PropTypes from 'prop-types';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';
import Icon from '@mdi/react';
import { Typography, Flex, Button } from 'antd';

const ContactFormatter = ({ row }) => {
  const { setUrlParams, searchParams } = useQueryParams();
  const { contact_id } = searchParams;

  const contactDetails = () => {
    setUrlParams({ contact_id: row?.contact?.id });
  };

  return (
    <div className='flex items-center w-full'>
      {!row?.contact?.id ? (
        <Icon path={mdiFilterOff} color={Ninja.colors.xlight} className='m-auto' />
      ) : (
        <Flex align='center' className='' gap={5} justify='space-between'>
          <Button
            onClick={contactDetails}
            type={contact_id == row.contact.id ? 'primary' : 'text'}
            className={contact_id == row.contact.id && '!text-white'}
          >
            <TableIcons
              color={Ninja.colors.light}
              icons={[
                {
                  icon: mdiInformation,
                  onClick: contactDetails,
                  tooltip: 'Contact Details',
                  size: 0.7,
                  color: contact_id == row.contact.id ? Ninja.colors.white : Ninja.colors.light,
                },
              ]}
            />
            {row?.contact?.email ? (
              <Typography className={`text-xs flex-nowrap truncate text-ellipsis ${contact_id == row.contact.id ? 'text-white' : ''}`}>
                {row?.contact?.email}
              </Typography>
            ) : row?.contact?.phone ? (
              <Typography className={`text-xs flex-nowrap truncate text-ellipsis ${contact_id == row.contact.id ? 'text-white' : ''}`}>
                Phone - {row?.contact?.phone}
              </Typography>
            ) : (
              <Typography className={`text-xs flex-nowrap truncate text-ellipsis ${contact_id == row.contact.id ? 'text-white' : ''}`}>
               ID - {row?.contact?.id}
              </Typography>
            )}
          </Button>
        </Flex>
      )}
    </div>
  );
};

export default ContactFormatter;

ContactFormatter.propTypes = {
  row: PropTypes.object,
};
