import React, { useCallback } from 'react';
import _debounce from 'lodash/debounce';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';
import { Input } from 'antd';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

const TableSearch = ({ searchRemote }) => {
  const { setExtraQueryParams, setLocalFilterText } = useNinjaTable();

  const handleChange = (value) => {
    if (searchRemote) {
      setExtraQueryParams((ps) => ({ ...ps, search: value }));
    } else {
      setLocalFilterText(value);
    }
  };

  const debounceHandleChange = useCallback(_debounce(handleChange, 500), [setExtraQueryParams]);

  return (
    <Input
      placeholder='Search'
      className='h-full'
      size='middle'
      suffix={<Icon path={mdiMagnify} />}
      onChange={(e) => {
        debounceHandleChange(e.target.value);
      }}
    />
  );
};

export default TableSearch;
