import React from 'react';
import { Divider } from 'antd';
import SingleInfoRow from 'components/ReportInfo/SingleInfoRow';

const GrossProfitInfo = ({ data, isLoading }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='flex flex-col w-[200px] '>
      <SingleInfoRow
        label='Deals'
        value={data.deals_profit}
        color='p'
        tooltip='Total of deal profits (Deal values minus COGs)'
      />
      <SingleInfoRow
        label='Postbacks'
        value={data.postbacks_value}
        color='p'
        tooltip='Total of postback values'
      />
      <SingleInfoRow
        label='Canceled Deals'
        value={-data.deals_profit_canceled}
        tooltip='Total  profits (Deal values minus COGs) of deals that has canceled status group assigned'
        color='n'
      />
      <Divider />
      <SingleInfoRow label='Total' value={data.gross_profit} />
    </div>
  );
};

GrossProfitInfo.propTypes = {};

export default GrossProfitInfo;
