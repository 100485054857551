import NinjaTable from 'components/tables/NinjaTable';
import React from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import Widget from 'components/Widget/Widget';
import ValueFormatter from 'components/table-formatters/values/ValueFormatter';
import { OwerflowNameFormatter } from 'components/tables/NinjaTable/formatters';
import { NameDefinition } from 'components/NameDefinition';
import getFormattedNumber from 'store/redux/utils/getFormattedNumber';

const ProductBundles = ({ height, index, movingButton, data, configuration }) => {
  const { dates } = useWidgetDate('product_bundles' + index);

  const columns = [
    {
      key: 'original_product_title',
      name: 'Original Product Title',
      resizable: true,
      width: 200,
      sortable: true,
      renderCell: ({ row }) => {
        return (
          <OwerflowNameFormatter
            title={row.original_product_title}
            className='!bg-background-light rounded-xl text-xs !font-semibold p-1.5 py-2.5 '
          />
        );
      },
      renderSummaryCell: () => 'Totals',
    },
    {
      key: 'bought_with_product_title',
      name: 'Bought With Product Title',
      width: 200,
      renderCell: ({ row }) => {
        return (
          <OwerflowNameFormatter
            title={row.bought_with_product_title}
            className='bg-error-light text-xs !font-semibold p-1.5 py-2.5 rounded-xl'
          />
        );
      },
      sortable: true,
      resizable: true,
    },
    {
      key: 'orders_count',
      name: 'Orders Count',
      sortable: true,
      renderCell: ValueFormatter,
      renderSummaryCell: ValueFormatter,
    },
    {
      key: 'orders_original_product',
      name: 'Orders Original product',
      sortable: true,
      renderCell: ValueFormatter,
    },
    {
      key: 'confidence',
      name: (
        <NameDefinition
          placement='bottom'
          name='Confidence'
          definiton='This score shows how likely customers are to buy these products together, based on data and shopping patterns. Higher scores indicate a stronger likelihood that these products will sell well as a bundle.'
        />
      ),
      renderCell: ({ row }) => {
        return `${getFormattedNumber(row.confidence)}%`;
      },
    },
  ];

  return (
    <Widget
      icon={
        data?.defaultFilter?.integration_type ? (
          <img
            src={`/icons/integrations/${data?.defaultFilter?.integration_type}.svg`}
            className='w-5 h-5 '
          />
        ) : null
      }
      tableWidget
      integrationType={data?.defaultFilter?.integration_type}
      widget_key={'product_bundles' + index}
      title={data.title}
      index={index}
      movingButton={movingButton}
      deleteWidget={true}
      data={data}
      default_filter={data.defaultFilter}
      configuration={configuration}
      disableDateFilter
      disableStatusFilter
      disableAccountsFilter
      integrationsSelect
      DisableFilterButton={false}
      design={false}
    >
      <NinjaTable
        columns={columns}
        dataProvider='integrationProductBundles'
        queryParams={{ ...dates }}
        cardProps={{ disable: true }}
        maxHeight={height}
        footerProps={{ readOnlyPagination: true }}
        tableProps={{ template: 'widget' }}
      />
    </Widget>
  );
};

export default ProductBundles;
