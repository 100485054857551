import { Switch } from 'antd';
import { snackbar } from 'components/notifications/notifications';
import React from 'react';
import { useChangeNetworkAccountStatusMutation } from 'store/redux/apis/network-accounts/network-accounts.api';
import Confirmation from 'components/Dialogs/Confirmation';

const NetworkAccountStatusSwitch = (props) => {
  const { size = 'small', active, ...rest } = props;

  /** @todo refactor when account is passed */
  const isActive = props.row?.account?.active ?? props.row?.active;
  const id = props.row?.account?.id ?? props.row?.id;
  const isManager = props.row?.account?.is_manager ?? props.row?.is_manager;

  const [changeNetworkAccountStatusQuery, { isLoading }] = useChangeNetworkAccountStatusMutation();

  if (isManager) {
    return null;
  }

  const onChange = () => {
    return changeNetworkAccountStatusQuery({
      id: id,
      active: Boolean(!isActive),
    })
      .unwrap()
      .then(() => {
        snackbar.success('Successfully changed status');
      })
      .catch(() => {
        snackbar.error('Error changing status');
      });
  };

  return (
    <Confirmation
      type='warning'
      onConfirm={onChange}
      description='Are you sure you want to change status?'
      className='w-fit'
    >
      <Switch size={size} disabled={isManager} checked={isActive} loading={isLoading} {...rest} />
    </Confirmation>
  );
};

export default NetworkAccountStatusSwitch;
