import React, { Fragment } from 'react';
import { useWidget } from 'components/Widget/useWidget';
import getFormattedNumber from 'store/redux/utils/getFormattedNumber';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import { metricsList as defaultList } from 'modules/DashboardModule/charts/components/metricsList';
import { DateFormatter } from 'components/charts/formatters';
import { Flex, Typography } from 'antd';

const CustomTooltip = ({ payload, name, dataKey, label, value_key }) => {
  const { filters } = useWidget();
  const { symbol, currency } = defaultList[value_key] || {};
  const value = payload[0]?.value;
  let formattedValue = value;
  if (symbol) {
    formattedValue = `${getFormattedNumber(value)}${symbol}`;
  } else if (currency) {
    formattedValue = getFormattedValue(value);
  }

  const { valueFormatter, formatter } = defaultList[value_key] || {};
  const ValueFormatter = valueFormatter || Fragment;
  const FormatterComponent = formatter || Fragment;

  const formattedDate = DateFormatter({ period: filters?.group_by || 'days', date: label });

  if (!payload[0]?.payload) {
    return null;
  }
  return (
    <div
      className='p-2 rounded shadow-xl gap-2 grid'
      style={{ background: 'rgba(255,255,255,0.95' }}
    >
      <div className='font-semibold text-sm'>{dataKey === 'date' ? formattedDate : label}</div>

      <Flex align='center' gap='middle'>
        <Typography className='text-sm'>{name}</Typography>
        <div className='text-sm'>
          {valueFormatter ? (
            <ValueFormatter value={value} />
          ) : formatter ? (
            <FormatterComponent row={payload[0]?.payload} fontSize={14} />
          ) : (
            formattedValue
          )}
        </div>
      </Flex>
    </div>
  );
};

export default CustomTooltip;
