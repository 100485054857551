import React, { useMemo } from 'react';
import { Skeleton } from 'antd';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import getFormattedNumber from 'store/redux/utils/getFormattedNumber';
import {
  mdiCashMultiple,
  mdiCart,
  mdiAccountMultiplePlus,
  mdiBank,
  mdiCash,
  mdiCashRefund,
  mdiCartPlus,
  mdiCalculatorVariant,
  mdiFormatListBulleted,
} from '@mdi/js';
import {
  ValueDotFormatter,
  RoiFormatter,
  RoasFormatter,
  LtvValueFormatter,
} from 'components/tables/NinjaTable/formatters';
import WidgetCard from 'components/widgets/components/WidgetCard';
import { RoiDefinition, RoasDefinition } from 'components/NameDefinition';
import ReportInfo from 'components/ReportInfo/ReportInfo';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import WidgetCharts from './charts/WidgetCharts';
import { useWidget } from 'components/Widget/useWidget';

const IntegrationWidget = ({ data, integration, loading, widget_key, totals }) => {
  const { chartConfig } = useWidget();
  const { date_from, date_to, date_range_key } = useWidgetDate(widget_key);

  const metrics = useMemo(() => {
    const metricsArray = [
      {
        key: 'ad_spent',
        label: 'Ad Spent',
        icon: mdiCash,
        definition: { name: 'Ad Spent', description: 'All Ads Spent with Store URLs' },
        value: (
          <>
            {getFormattedValue(data.ad_spent)}
            <ReportInfo
              type='ad_spent'
              info={{ date_from, date_to, date_range_key, integration: integration }}
            />
          </>
        ),
      },
      {
        key: 'gross_income',
        icon: mdiCashMultiple,
        label: 'Gross Income',
        definition: { name: 'Gross Income', description: 'Gross Revenue from the sales' },
        value: (
          <>
            <ValueDotFormatter value={data.gross_income} />
            <ReportInfo
              type='gross_income'
              info={{ date_from, date_to, date_range_key, integration: integration }}
            />
          </>
        ),
      },
      {
        key: 'cogs',
        icon: mdiCash,
        label: 'COGS',
        definition: {
          name: 'COGS',
          description: (
            <div className='max-w-72'>
              SUM of Cost of Goods Sold (COGS) <br /> If you have Product Costs set up in data, we
              automatically fetch them.If not, the COGS will default to 0, and you can manually
              enter the values on our Deals page for each order.
            </div>
          ),
        },
        value: getFormattedValue(data.cogs) || 0,
      },
      {
        key: 'gross_profit',
        icon: mdiBank,
        label: 'Gross Profit',
        definition: { name: 'Gross Profit', description: 'Gross Income - COGS' },
        value: (
          <>
            {getFormattedValue(data.gross_profit)}
            <ReportInfo
              type='gross_profit'
              info={{
                date_from: date_from,
                date_to: date_to,
                date_range_key: date_range_key,
                integration: integration,
              }}
            />
          </>
        ),
      },
      {
        key: 'net_profit',
        icon: mdiBank,
        label: 'Net Profit',
        definition: { name: 'Net Profit', description: 'Gross Profit - Ad Spent' },
        value: (
          <>
            {getFormattedValue(data.net_profit)}
            <ReportInfo
              type='net_profit'
              info={{
                date_from: date_from,
                date_to: date_to,
                date_range_key: date_range_key,
                integration: integration,
              }}
            />
          </>
        ),
      },
      {
        key: 'roi',
        existDefinition: <RoasDefinition showIcon />,
        label: 'ROAS',
        formatter: () => <RoasFormatter row={data} fontSize={18} />,
      },
      {
        key: 'roas',
        existDefinition: <RoiDefinition showIcon />,
        label: 'ROAS',
        formatter: () => <RoiFormatter row={data} fontSize={18} />,
      },
      {
        key: 'new_customer_orders',
        icon: mdiAccountMultiplePlus,
        label: 'New Customer Orders',
        definition: {
          name: 'New Customer Orders',
          description:
            'The number of orders placed by newly registered customers within the selected timeframe.',
        },
        value: data.new_customer_orders || 0,
      },
      {
        key: 'orders',
        icon: mdiCart,
        definition: { name: 'Orders', description: 'Total Number of Orders' },
        label: 'Orders',
        value: data.orders || 0,
      },
      {
        key: 'avg_order_value',
        label: 'Avg. Order Value',
        icon: mdiCalculatorVariant,
        definition: {
          name: 'Avg. Order Value',
          description: 'Total Gross Income / Number of Orders',
        },
        value: getFormattedValue(data.avg_order_value) || 0,
      },
      {
        key: 'avg_profit_per_order',
        icon: mdiCartPlus,
        label: 'Avg. Profit Per Order',
        definition: {
          name: 'Avg. Profit Per Order',
          description: 'Gross Profit / Number of Orders',
        },
        value: getFormattedValue(data.avg_profit_per_order) || 0,
      },
      {
        key: 'cac',
        icon: mdiCash,
        label: 'CAC',
        definition: { name: 'CAC', description: 'Ad Spent / Number of New Customer Orders' },
        value: getFormattedValue(data.cac) || 0,
      },

      data.hasOwnProperty('refund_rate') && {
        key: 'refund_rate',
        icon: mdiCashRefund,
        label: 'Refund Rate',
        value: `${getFormattedNumber(data?.refund_rate)}%`,
      },

      data.hasOwnProperty('quantity') && {
        key: 'quantity',
        icon: mdiFormatListBulleted,
        label: 'Unit Sold',
        value: `${getFormattedNumber(data?.quantity)}`,
      },

      data.hasOwnProperty('ltv') && {
        key: 'ltv',
        icon: mdiFormatListBulleted,
        label: 'Lifetime Value',
        value: `${getFormattedValue(data?.ltv)}`,
        content: <LtvValueFormatter data={data} />,
      },
    ];

    return metricsArray.filter(Boolean);
  }, [data, integration, loading, date_from, date_to, date_range_key, widget_key]);

  return (
    <Skeleton loading={loading} active>
      <div className='sm:grid flex flex-col grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 m-auto items-center h-full w-full overflow-x-auto'>
        {metrics.map((metric, index, key) => (
          <>
            {chartConfig?.load_chart ? (
              <WidgetCharts
                data={data.data}
                total={data?.totals}
                index={metric.key}
                totals={totals}
                {...metric}
              />
            ) : (
              <WidgetCard key={index} {...metric} />
            )}
          </>
        ))}
      </div>
    </Skeleton>
  );
};

export default IntegrationWidget;
