import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import SingleInfoRow from 'components/ReportInfo/SingleInfoRow';

const GrossIncomeInfo = ({ data, isLoading }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='flex flex-col w-[200px] '>
      <SingleInfoRow label='Deals' value={data.deals_value} tooltip='Total deal values' color='p' />
      <SingleInfoRow
        label='Postbacks'
        value={data.postbacks_value}
        tooltip='Total postbacks values'
        color='p'
      />
      <SingleInfoRow
        label='Canceled Deals'
        value={-data.deals_value_canceled}
        tooltip='Total deal values that has canceled status group assigned'
        color='n'
      />
      <Divider />
      <SingleInfoRow label='Total' value={data.gross_income} />
    </div>
  );
};

GrossIncomeInfo.propTypes = {};

export default GrossIncomeInfo;
