import React from 'react';
import {
  TrackingStateFormatter,
  NameFormatter,
  NetworkFormatter,
  NotTrackingFormatter,
} from 'components/tables/NinjaTable/formatters';
import copyToClipboard from 'common/utils/copyToClipboard';
import Ninja from '@ninja';
import { mdiContentCopy } from '@mdi/js';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';

const AccountFormatter = ({ disableNetwork = false, row }) => {
  const { account } = row;

  if (!account) {
    return '-';
  }

  const copyName = (e) => {
    e.stopPropagation();

    copyToClipboard(account.name);
  };

  const icons = [
    {
      icon: mdiContentCopy,
      onClick: copyName,
      tooltip: 'Copy Name',
      onlyOnHover: true,
      show: account?.name,
    },
  ];

  return (
    <div className='gap-2 flex justify-between text-left w-full text-xs bg-background-light p-1.5 rounded-md items-center h-8 truncate'>
      {!disableNetwork && <NetworkFormatter row={row.account} />}
      {account?.network !== 'facebook' && (
        <>
          <NotTrackingFormatter data={account} />
          <TrackingStateFormatter isTracked={account?.is_tracked} />
        </>
      )}
      <NameFormatter name={account.name} />
      <TableIcons color={Ninja.colors.light} icons={icons} />
    </div>
  );
};

export default AccountFormatter;
