import React from 'react';
import Icon from '@mdi/react';
import { mdiPoll } from '@mdi/js';

const NoResultFound = ({ height = '100%' }) => {
  return (
    <div className='flex items-center justify-center  w-full' style={{ height: height }}>
      <div className='grid gap-2 text-color-xlight '>
        <Icon path={mdiPoll} size={2} className='m-auto' />
        <span className='font-normal'>No Data Found</span>
      </div>
    </div>
  );
};

export default NoResultFound;
