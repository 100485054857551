import React from 'react';
import { Tag, Flex } from 'antd';
import { useWidget } from '../useWidget';
import PropTypes from 'prop-types';
import useIntegrationStores from 'modules/apps/api/useIntegrationStores';

const ShopifyAccountsTags = ({ default_accounts }) => {
  const { shopify } = useIntegrationStores();

  const ShopifyAccountsTags = shopify?.filter((account) =>
    default_accounts.find((id) => id === account.id)
  );

  return (
    <Flex gap='smal' wrap>
      {ShopifyAccountsTags.map((account) => (
        <Tag
          key={`account-${account.id}`}
          bordered={false}
          className='font-normal text-[11px] bg-background-light h-7 flex items-center rounded-2xl'
        >
          <img width='14' src='/icons/integrations/shopify.svg' alt={account.network} />
          <span className='font-medium flex-nowrap text-ellipsis'>{account.name}</span>
        </Tag>
      ))}
    </Flex>
  );
};

export default ShopifyAccountsTags;

ShopifyAccountsTags.propTypes = {
  default_accounts: PropTypes.array.isRequired,
};
