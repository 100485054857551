import React, { useState, Fragment } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import { useWidget } from 'components/Widget/useWidget';
import { useNetworksReportsQuery } from 'store/redux/apis/dashboard.api';
import ChartLoading from 'components/charts/components/ChartLoading';
import NoResultFound from 'components/charts/components/NoResultFound';
import { Flex } from 'antd';
import {
  RoiFormatter,
  RoasFormatter,
  ValueDotFormatter,
  SpentFormatter,
} from 'components/tables/NinjaTable/formatters';
import getFormattedNumber from 'store/redux/utils/getFormattedNumber';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

export const metricsList = {
  ad_spent: { color: '#FF0000', name: 'Ad Spent', formatter: SpentFormatter },
  net_profit: { color: 'rgb(145, 199, 22)', name: 'Net Profit', valueFormatter: ValueDotFormatter },
  gross_income: { color: '#ffbb02', name: 'Gross Income', valueFormatter: ValueDotFormatter },
  gross_profit: { color: '#7C899D', name: 'Gross Profit', valueFormatter: ValueDotFormatter },
  roi: { color: '#ff4e00', name: 'ROI', type: 'line', formatter: RoiFormatter, opacity: 0.7 },
  roas: { color: '#F09319', name: 'ROAS', type: 'line', formatter: RoasFormatter, opacity: 0.7 },
};

const networkColors = {
  google: '#FBBC04',
  facebook: '#0081FB',
  tiktok: '#000000',
  bing: '#FF7043',
};

const NetworkChart = ({ height }) => {
  const { filters, chartConfig } = useWidget();
  const [hoveredIndex, setHoveredIndex] = useState(null); // To track the hovered index
  const [hoveredMetric, setHoveredMetric] = useState(null); // To track the hovered metric

  const { data = [], isLoading } = useNetworksReportsQuery({
    date_from: filters.date_from,
    date_to: filters.date_to,
  });

  const transformedData = Object.values(data).map((item) => ({
    network: item.network,
    gross_income: Math.abs(item.gross_income),
    gross_profit: Math.abs(item.gross_profit),
    net_profit: Math.abs(item.net_profit),
    ad_spent: Math.abs(item.ad_spent),
    roi: Math.abs(item.roi),
    roas: Math.abs(item.roas),
    date: item.date,
  }));

  const CustomTooltip = ({ payload }) => {
    const { name, symbol, currency } = metricsList[payload[0]?.dataKey] || {};
    let formattedValue = payload[0]?.value;
    if (symbol) {
      formattedValue = `${getFormattedNumber(payload[0]?.value)}${symbol}`;
    } else if (currency) {
      formattedValue = getFormattedValue(payload[0]?.value);
    }

    const { valueFormatter, formatter } = metricsList[payload[0]?.dataKey] || {};
    const ValueFormatter = valueFormatter || Fragment;
    const FormatterComponent = formatter || Fragment;

    return (
      <div
        className='grid justify-center w-full gap-1 p-2 rounded-md shadow-md'
        key={payload[0]?.dataKey}
        style={{ background: 'rgba(255,255,255,0.9)' }}
      >
        <img src={`/icons/networks/${payload[0]?.name}.svg`} className='w-4 h-4 m-auto' />
        <Flex gap='middle' align='center'>
          <span className='text-md font-normal'>{name}</span>
          <span>
            {valueFormatter ? (
              <ValueFormatter value={data[payload[0]?.name][payload[0]?.dataKey]} />
            ) : formatter ? (
              <FormatterComponent row={data[payload[0]?.name]} fontSize={14} />
            ) : (
              formattedValue
            )}
          </span>
        </Flex>
      </div>
    );
  };
  const handleMouseEnter = (index, metric) => {
    setHoveredIndex(index); // Set the hovered slice index
    setHoveredMetric(metric); // Set the hovered metric
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null); // Reset when the mouse leaves
    setHoveredMetric(null); // Reset the hovered metric
  };

  if (isLoading) {
    return <ChartLoading height={height} />;
  }

  if (!transformedData?.length && !chartConfig.metric) {
    return <NoResultFound height={height} />;
  }

  return (
    <ResponsiveContainer width='100%' height={height}>
      {/* <PieChart width='100%' height='100%'>
        <Pie
          data={transformedData}
          dataKey={chartConfig.metric[0]}
          nameKey='network'
          outerRadius={60}
          onMouseEnter={(e) => handleMouseEnter(e.index, chartConfig.metric[0])}
          onMouseLeave={handleMouseLeave}
        >
          {transformedData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={networkColors[entry.network]}
              opacity={hoveredIndex === index && hoveredMetric === chartConfig.metric[0] ? 1 : 0.7}
              onMouseEnter={() => handleMouseEnter(index, chartConfig.metric[0])}
              onMouseLeave={handleMouseLeave}
            />
          ))}
        </Pie>
        <Pie
          data={transformedData}
          dataKey={chartConfig.metric[1]}
          nameKey='network'
          innerRadius={70}
          onMouseEnter={(e) => handleMouseEnter(e.index, chartConfig.metric[1])}
          onMouseLeave={handleMouseLeave}
        >
          {transformedData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={networkColors[entry.network]}
              opacity={hoveredIndex === index && hoveredMetric === chartConfig.metric[1] ? 1 : 0.7}
              onMouseEnter={() => handleMouseEnter(index, chartConfig.metric[1])}
              onMouseLeave={handleMouseLeave}
            />
          ))}
        </Pie>

        <Legend
          payload={transformedData.map((item, index) => ({
            value: <img src={`/icons/networks/${item.network}.svg`} className='w-4 h-4' />,
            type: 'square',
            id: item.network,
            color: networkColors[item.network],
          }))}
        />
        <Tooltip content={CustomTooltip} />
      </PieChart> */}
    </ResponsiveContainer>
  );
};

export default NetworkChart;
