import React, { useState, useMemo, useEffect } from 'react';
import { Menu } from 'antd';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiFormatListBulleted, mdiApps } from '@mdi/js';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import P from 'router/paths';

const ReportingMainMenu = ({ dateRangeComponent }) => {
  const [selected, setSelected] = useState();
  const history = useHistory();
  const location = useLocation();
  const { queryParams } = useWidgetDate('global');
  const queryString = useMemo(() => queryParams, [queryParams]);

  const appendQuery = (path) => (queryString ? `${path}?${queryString}` : path);

  const items = [
    {
      label: <Link to={appendQuery(P.REPORTING.INDEX)}>Reporting</Link>,
      key: 'reporting',
      icon: <Icon path={mdiFormatListBulleted} />,
    },
    {
      label: 'Creative Analytics',
      key: 'creative_reporting',
      icon: <Icon path={mdiApps} />,
      children: [
        {
          key: 'meta',
          label: (
            <Link to={`${P.REPORTING.CREATIVE}${queryParams}&network=meta`}>
              Creative Analytics
            </Link>
          ),
          icon: <img src='/icons/networks/meta.svg' alt='Meta' className='w-5 h-5' />,
        },
        {
          key: 'tiktok',
          label: (
            <Link to={`${P.REPORTING.CREATIVE}${queryParams}&network=tiktok`}>
              Creative Analytics
            </Link>
          ),
          icon: <img src='/icons/networks/tiktok.svg' alt='TikTok' className='w-5 h-5' />,
        },
      ],
    },
  ];

  const findItemByPath = (items, path) => {
    for (const item of items) {
      if (item.children) {
        const found = findItemByPath(item.children, path);
        if (found) {
          return found;
        }
      }
      if (item.label?.props?.to?.startsWith(path)) {
        return item;
      }
    }
    return null;
  };

  useEffect(() => {
    const selectedItem = findItemByPath(items, location.pathname);
    setSelected(selectedItem ? selectedItem.key : 'cretive-reporting');
  }, [location.pathname, items]);

  const onSelect = ({ key }) => {
    const findItemByKey = (items, key) => {
      for (const item of items) {
        if (item.key === key) {
          return item;
        }
        if (item.children) {
          const found = findItemByKey(item.children, key);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    const selectedItem = findItemByKey(items, key);
    if (selectedItem) {
      setSelected(key);
      const url = selectedItem.url;
      if (url) {
        history.push(appendQuery(url));
      }
    }
  };

  return (
    <div className='flex px-4 pr-8 items-center justify-between flex-col sm:flex-row gap-2 pt-1'>
      <Menu
        className='w-full order-1 sm:order-0 !border-none'
        mode='horizontal'
        selectedKeys={[selected]}
        onSelect={onSelect}
        items={items}
      />
      <div className='sm:max-w-44 order-0 sm:order-1 w-full'>{dateRangeComponent}</div>
    </div>
  );
};

export default ReportingMainMenu;
