import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import P from 'router/paths';
import Icon from '@mdi/react';
import { mdiArrowBottomLeftThick, mdiArrowTopRightThick } from '@mdi/js';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const items = [
  {
    label: 'Received Postbacks',
    key: 'received_postbacks',
    url: P.POSTBACKS.INDEX,
    icon: <Icon path={mdiArrowBottomLeftThick} size={0.8} />,
  },
  {
    label: 'Outgoing Postbacks',
    key: 'outgoing_postbacks',
    url: P.POSTBACKS.OUTGOING.INDEX,
    icon: <Icon path={mdiArrowTopRightThick} size={0.8} />,
  },
];

const PostbacksMenu = ({ dateRangeComponent }) => {
  const { queryParams } = useWidgetDate('global');

  const [selected, setSelected] = useState();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const key = items.find((item) => item.url === location.pathname)?.key;
    setSelected(key);
  }, [location.pathname]);

  const onSelect = ({ key }) => {
    const selectedItem = items.find((item) => item.key === key);
    if (selectedItem) {
      history.push(selectedItem.url + queryParams);
      setSelected(key);
    }
  };

  return (
    <div className='flex px-4 pr-8 items-center justify-between flex-col sm:flex-row gap-2 pt-1'>
      <Menu
        className='w-full order-1 sm:order-0 !border-none'
        mode='horizontal'
        selectedKeys={[selected]}
        onSelect={onSelect}
        items={items}
      />
      <div className='sm:max-w-44 order-0 sm:order-1 w-full'>{dateRangeComponent}</div>
    </div>
  );
};

export default PostbacksMenu;
