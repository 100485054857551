import React from 'react';
import { Tag } from 'antd';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

const LtvValueFormatter = ({ data }) => {

  if (data?.ltv === null) {
    return null;
  }
    
  return (
    <div className='grid gap-1'>
      <Tag className='text-xs font-semibold' bordered={false}>
        30 days - {getFormattedValue(data?.ltv_30_days)}
      </Tag>
      <Tag className='text-xs font-semibold' bordered={false}>
        90 days - {getFormattedValue(data?.ltv_90_days)}
      </Tag>
      <Tag className='text-xs font-semibold' bordered={false}>
        180 days - {getFormattedValue(data?.ltv_180_days)}
      </Tag>
    </div>
  );
};

export default LtvValueFormatter;
