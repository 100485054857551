import React from 'react';
import { Typography } from 'antd';

const WidgetTitle = ({ icon, title }) => {
  return (
    <div className='flex items-center gap-2'>
      {icon}
      <div style={{ maxWidth: '60%' }}>
        <Typography className='text-sm w-full overflow-hidden text-ellipsis text-left font-bold'>
          {title || '-'}
        </Typography>
      </div>
    </div>
  );
};

export default WidgetTitle;
