import Widget from 'components/Widget/Widget';
import NinjaTable from 'components/tables/NinjaTable';
import React, { useMemo } from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import PropTypes from 'prop-types';
import { AccountFormatter } from 'components/tables/NinjaTable/formatters/sourceFormatters';
import WidgetsCommonColumns from 'components/widgets/components/WidgetsCommonColumns';

const StatsByAdAccount = ({ height, movingButton, data, index, configuration }) => {
  const { dates } = useWidgetDate('network_total_statistics' + index);

  const columns = useMemo(
    () => [
      {
        name: 'name',
        key: 'network_accounts.name',
        resizable: true,
        sortable: true,
        cellClass: 'stretch',
        minWidth: 250,
        frozen: true,
        renderCell: AccountFormatter,
        renderSummaryCell: () => {
          return 'Totals';
        },
      },
      ...WidgetsCommonColumns(),
    ],
    [dates, index]
  );

  return (
    <Widget
      tableWidget
      widget_key={'network_total_statistics' + index}
      title={data.title}
      index={index}
      disableLimit
      disableLevelFilter
      movingButton={movingButton}
      data={data}
      disableStatusFilter
      default_filter={data.defaultFilter}
      configuration={configuration}
      adsFilter
      adsetFilter
      campaignFilter
      disableDateFilter
      DisableFilterButton={false}
      showDatePicker
    >
      <NinjaTable
        columns={columns}
        dataProvider='networksTotalStatistics'
        queryParams={{ ...dates }}
        disableFooter={true}
        cardProps={{ disable: true }}
        maxHeight={height}
        tableProps={{ template: 'widget' }}
      />
    </Widget>
  );
};

export default StatsByAdAccount;

StatsByAdAccount.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  movingButton: PropTypes.element.isRequired,
  height: PropTypes.number.isRequired,
};
