import ninjaApi from 'store/redux/apis/ninja.api';
import useEffectOnce from 'utils/shared/useEffectOnce';
import storage from 'utils/storage/advanced-storage';
import { authenticateAPI } from 'utils/auth/token-storage';
import { tokenKey } from '@ninja/consts';

/**
 * @returns {{
 * user: ?User,
 * }}
 */
export default function useAuth() {
  const [trigger, { data: userData, isLoading, isFetching, isUninitialized }] =
    ninjaApi.endpoints.bootstrapUser.useLazyQuery();

  console.log('initialized', isUninitialized);

  useEffectOnce(() => {
    // Listen to token changes
    storage.on('change', tokenKey, onAccessTokenUpdateListener);

    // Check if token exists in storage, if exists then bootstrap with that token
    const current_token = storage.get(tokenKey);
    if (current_token) {
      bootstrap(current_token);
    }
  }, ['useAuth']);

  /**
   * Event listener callack, when token changes
   * @param {String|any} token current token in storage
   * @param {String|any} oldToken old token in storage
   * @returns {void}
   */
  const onAccessTokenUpdateListener = (token, oldToken) => {
    if (token === oldToken) return;
    bootstrap(token);
  };

  /**
   * Bootstrap user with token
   * @param {String} token
   * @returns {Promise}
   */
  const bootstrap = (token) => {
    authenticateAPI(token);
    return trigger().unwrap();
  };

  /**
   * Set token in storage
   * @param {String} token
   */
  const loginWithToken = (token) => {
    authenticateAPI(token);
    storage.set(tokenKey, token);
  };

  /**
   * Logout user
   * @param {String} token
   */
  const logout = (token) => {
    storage.remove(tokenKey);
  };

  const loading = isLoading || isFetching || (isUninitialized && storage.has(tokenKey));

  return {
    loginWithToken,
    logout,
    token: userData?.token || null,
    loading: loading,
    isLoading: loading,
    auth: Boolean(userData?.user?.id),
    user: userData?.user || null,
  };
}

/**
 * @typedef {Object} User
 * @property {Number} id
 * @property {Number} default_workspace_id
 * @property {String} email
 * @property {String|null} avatar
 * @property {String} name
 * @property {String} lastname
 * @property {Boolean} email_confirmed
 * @property {?String} confirmation_sent_at
 * @property {String} country_code
 * @property {UserRole} role
 * @property {RevenueSource} revenue_source
 * @property {UserInfo} info
 */

/**
 * @typedef {"ADMIN"|"OWNER"|"EDITOR"|"VIEWER"} UserRole
 */

/**
 * @typedef {"ninja"|"network"} RevenueSource
 */

/**
 * @typedef {Object} UserInfo
 * @property {String} address
 * @property {String} country
 * @property {String} phone_number
 */
