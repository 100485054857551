import dayjs from 'dayjs';

const DateFormatter = ({ period, date }) => {
  if (period === 'year') {
    return dayjs(date).format('YYYY');
  } else if (period === 'month') {
    return dayjs(date).format('YYYY-MMM');
  } else {
    return dayjs(date).format('YYYY-MM-DD');
  }
};

export default DateFormatter;
