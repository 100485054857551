import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import * as yup from 'yup';
import { Input, Button, Form, Typography, Checkbox } from 'antd';
import { useRegisterMutation } from 'store/redux/apis/user.api';
import handleFormikErrors from '@lib/formik/handleFormikErrors';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import { Link } from 'react-router-dom';
import P from 'router/paths';
import useEmailValidation from 'hooks/useEmailValidation';
import useBootstrapUser from 'store/redux/hooks/actions/useBootstrapUser';
import UrlHelper from 'common/utils/url-helper';
import { ninjaEvents } from 'tracking/ninjaEvents';
import useSearchParams from 'hooks/useSearchParams';

const RegisterPage = () => {
  const [register, { isLoading: isRegisterLoading }] = useRegisterMutation();
  const {
    validate: validateEmail,
    isValidated: isEmailValidated,
    isValid: isEmailValid,
    isLoading: isEmailValidationLoading,
    isInvalid: isEmailInvalid,
    error: emailErrors,
  } = useEmailValidation();
  const { bootstrapWithToken } = useBootstrapUser();
  const params = useSearchParams();

  const invite_token = params.invite_token;
  const email = params.email;
  const plan_code = params.plan;
  const nj_user_id = params.nj_user_id;
  const nj_event_id = params.nj_event_id;
  const appsumo_ref_id = params.appsumo_ref_id;
  const saaszilla_ref_id = params.saaszilla_ref_id;

  console.log(params);

  useEffect(() => {
    form.setFieldValue('shopify_domain', params.domain);
  }, [params]);

  const data = [
    { text: 'Multiple Ad Networks' },
    { text: 'Real Time Profit/Loss Data Per Ad/Product' },
    { text: '1 Click Management of Multiple Ad Networks Accounts' },
    { text: 'Our Basic CRM' },
    { text: 'Easy Integrations of the Most Popular E-Commerce Platforms' },
    { text: 'Custom URL Tracking' },
    { text: 'Pixel & Server Side Tracking' },
    { text: 'Automatic Api Conversions' },
  ];

  const sendLeadToDataninjaCrm = (data) => {
    window.nj?.form
      .fill('q7PsQh4NhSG8V2ObcSiPRFVAN1mOnu')
      .setField('first-last-name', form.values.name + ' ' + form.values.last_name)
      .setField('e-mail', form.values.email)
      .setField('user-id', data.user_id)
      .setField('njuser', form.values.nj_user_id)
      .submit();
  };

  const registerUser = (data) => {
    if (isEmailValidated && !isEmailValid) return alert('Please Enter Valid Email');

    if (!form.values.agreed) return alert('Please agree to the terms and conditions');
    data.ninja_user_id = window.nj.getUserId();
    data.plan_code = plan_code;
    register(data)
      .unwrap()
      .then((data) => {
        if (data?.integrations?.shopify_redirect_url) {
          window.open(data?.integrations?.shopify_redirect_url, '_blank');
        }

        ninjaEvents.emit('register', data);

        try {
          sendLeadToDataninjaCrm(data);
        } catch (err) {
          console.log(err);
        }
        if (data.token) {
          bootstrapWithToken(data.token);
        }
      })
      .catch((err) => {
        handleFormikErrors(err);
      });
  };

  const form = useFormik({
    initialValues: {
      name: '',
      last_name: '',
      email: email ? String(atob(email)) : '',
      password: '',
      password_confirmation: '',
      invite_token: invite_token ? String(invite_token) : null,
      nj_user_id,
      nj_event_id,
      appsumo_ref_id,
      saaszilla_ref_id,
      agreed: true,
      shopify_domain: '',
    },
    validationSchema: validationSchema,
    onSubmit: registerUser,
    validateOnChange: false,
  });

  const checkEmail = () => {
    // if (!String(form.values.email).includes('@')) return;

    validateEmail(form.values.email);
  };

  useEffect(() => {
    if (invite_token && form.values.email) {
      validateEmail(form.values.email);
    }
  }, [invite_token]);

  return (
    <div className='w-full h-full p-4 md:p-8 overflow-auto flex flex-center min-h-[100%] bg-background-dark '>
      <div className='grid items-center justify-center  w-full m-auto'>
        <div className='md:flex grid items-start justify-centerflex-wrap gap-4'>
          <div className='grid gap-4'>
            <span className='text-3xl font-bold text-orange'>Start Using MyDataNinja Now</span>
            <span className='text-white text-base font-bold md:max-w-md'>
              Join MyDataNinja and increase your ROI and Effectiveness of your Ad Spent
            </span>
            <span className='text-white text-md font-semibold'>
              What's included in MyDataNinja?
            </span>
            <div className='grid gap-2'>
              {data.map((item, index) => (
                <div key={index} className='flex gap-1 items-center'>
                  <Icon path={mdiCheck} className='text-green' size={1} />
                  <span className='text-white'>{item.text}</span>
                </div>
              ))}
            </div>
          </div>
          <div className='flex gap-2  items-center flex-col'>
            <div className='w-full bg-white p-5 rounded-md shadow-md  transition-all duration-300 lg:min-w-[700px] md:min-w-[500px]'>
              <div>
                <p className='font-bold text-xl text-color-dark-text mb-5'>Create your Account</p>
              </div>
              <form className='disable-antd-margin' onSubmit={form.handleSubmit}>
                <div className='grid grid-cols-2 flex-wrap gap-4'>
                  <div className='col-span-2 md:col-span-1'>
                    <Form.Item
                      hasFeedback
                      {...formikFieldToAntProps(form.errors, form.touched, 'name')}
                    >
                      <Input
                        size='large'
                        placeholder='First Name'
                        name='name'
                        value={form.values.name}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                      />
                    </Form.Item>
                  </div>

                  <div className='col-span-2 md:col-span-1'>
                    <Form.Item
                      hasFeedback
                      {...formikFieldToAntProps(form.errors, form.touched, 'last_name')}
                    >
                      <Input
                        size='large'
                        value={form.values.last_name}
                        onBlur={form.handleBlur}
                        onChange={form.handleChange}
                        placeholder='Last Name'
                        name='last_name'
                      />
                    </Form.Item>
                  </div>
                  <div className='col-span-2'>
                    <Form.Item
                      hasFeedback
                      {...formikFieldToAntProps(form.errors, form.touched, 'email')}
                      validateStatus={
                        form.errors.email
                          ? 'error'
                          : isEmailValidationLoading
                          ? 'validating'
                          : isEmailValidated && isEmailValid
                          ? 'success'
                          : isEmailValidated && isEmailInvalid
                          ? 'error'
                          : undefined
                      }
                      help={
                        emailErrors
                          ? emailErrors
                          : form.touched.email && form.errors.email
                          ? form.errors.email
                          : null
                      }
                    >
                      <Input
                        size='large'
                        type='email'
                        placeholder='Email'
                        name='email'
                        value={form.values.email}
                        onChange={(e) => {
                          form.setFieldValue('email', e.target.value);
                        }}
                        onBlur={(e) => {
                          form.handleBlur(e);
                          checkEmail();
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='col-span-2 '>
                    <Form.Item
                      hasFeedback
                      {...formikFieldToAntProps(form.errors, form.touched, 'password')}
                    >
                      <Input.Password
                        size='large'
                        type='password'
                        placeholder='Password'
                        name='password'
                        value={form.values.password}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                      />
                    </Form.Item>
                  </div>
                  <div className='col-span-2 '>
                    <Form.Item
                      hasFeedback
                      {...formikFieldToAntProps(form.errors, form.touched, 'password_confirmation')}
                    >
                      <Input.Password
                        size='large'
                        type='password'
                        placeholder='Confirm Password'
                        name='password_confirmation'
                        value={form.values.password_confirmation}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='flex-center pt-4'>
                  <Checkbox checked={form.values.agreed} onChange={form.handleChange} name='agreed'>
                    <span>
                      By signing up, I agree to the &nbsp;
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href={UrlHelper.home('/terms-and-conditions')}
                      >
                        terms of use,
                      </a>
                      &nbsp;
                      <a target='_blank' rel='noreferrer' href={UrlHelper.home('/privacy-policy')}>
                        privacy policy
                      </a>
                      &nbsp; and customer terms.
                    </span>
                  </Checkbox>
                </div>
                <div className='flex-center pt-4'>
                  <Button loading={isRegisterLoading} htmlType='submit' size='large' type='primary'>
                    Continue
                  </Button>
                </div>
              </form>
            </div>
            <Typography.Text className='text-white text-sm font-bold'>
              Already Have An Account?{' '}
              <Link to={P.AUTH.LOGIN} className='text-white text-sm link'>
                Log In Here
              </Link>
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;

const validationSchema = yup.object({
  name: yup.string('Enter your name').min(1, 'Enter Your Name').required('Enter your name'),
  last_name: yup.string('Enter your lastname').required('Enter your lastname'),
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
    .matches('^(?=.*[a-z])(?=.*[A-Z])', 'Must contain one uppercase and one lowercase'),
  password_confirmation: yup
    .string()
    .required('Please Confirm Password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});
