import EventEmitter from '@ninja/core/EventEmitter';
import { colors } from 'common/@mui/theme/colors';

class Ninja {
  static event = EventEmitter;

  static normalize = (data, id_key = 'id') => {
    if (!Array.isArray(data)) {
      return data;
    }

    return data.reduce((acc, d) => {
      acc[d[id_key]] = d;
      return acc;
    }, {});
  };

  static heavyCalculation(duration = 100) {
    const start = performance.now();
    while (performance.now() - start < duration) {
      // Simulating heavy computation
      Math.random();
    }
  }

  static normalizeAsMap = (data, id_key = 'id') => {
    if (!Array.isArray(data)) {
      return data;
    }
    const map = new Map();
    for (let i = 0, l = data.length; i < l; i++) {
      map.set(data[i][id_key], data[i]);
    }
    return map;
  };

  static getUrlParam = (name, def = null) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (null != urlParams.get(name)) {
      return urlParams.get(name);
    }

    return def;
  };
}

Ninja.colors = colors;

export default Ninja;
