import React from 'react';
import { Tooltip } from 'antd';

const IntegrationFormatter = ({ row }) => {
  if (!row.integration) {
    return (
      <Tooltip
        className='flex items-center gap-2'
        placement='top'
        title={<span className='capitalize'>MyDataNinja</span>}
        destroyTooltipOnHide={true}
        mouseEnterDelay={0.1}
      >
        <img src='/icons/integrations/mydataninja.svg' className='w-5 h-5 m-auto' />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      className='flex items-center gap-2'
      placement='top'
      title={<span className='capitalize'>{row?.integration?.name}</span>}
      destroyTooltipOnHide={true}
      mouseEnterDelay={0.1}
    >
      <img
        src={`/icons/integrations/${row?.integration?.type.toLowerCase()}.svg`}
        alt={row?.integration?.name}
        className='w-5 h-5 m-auto'
      />
    </Tooltip>
  );
};

export default IntegrationFormatter;