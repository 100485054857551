import Widget from 'components/Widget/Widget';
import NinjaTable from 'components/tables/NinjaTable';
import React, { useMemo } from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import WidgetsCommonColumns from 'components/widgets/components/WidgetsCommonColumns';
import { NetworkFormatter } from 'components/tables/NinjaTable/formatters';
import Icon from '@mdi/react';
import { mdiCloseCircleOutline, mdiLinkVariant } from '@mdi/js';

const icons = {
  untracked: <Icon path={mdiCloseCircleOutline} />,
  custom_url: <Icon path={mdiLinkVariant} />,
  organic: <Icon path={mdiLinkVariant} />,
};

const ProfitsAndLossesByChannel = ({ height, movingButton, data, index, configuration }) => {
  const { dates } = useWidgetDate('channelsProfitAndLoss' + index);

  const networkNames = {
    facebook: 'Meta',
    google: 'Google',
    bing: 'Bing',
    tiktok: 'TikTok',
    untracked: 'Non Attributed',
    custom_url: 'Custom URLs',
    organic: 'Custom URLs',
  };

  const columns = useMemo(
    () => [
      {
        name: 'Channel',
        key: 'network',
        minWidth: 160,
        resizable: true,
        frozen: true,
        renderCell: ({ row }) => {
          return (
            <div className='flex items-center gap-2'>
              {typeof icons[row.network] !== 'undefined' ? (
                icons[row.network]
              ) : (
                <NetworkFormatter
                  title={networkNames[row.network] || 'Custom URLs'}
                  row={{ network: networkNames[row.network] || 'organic' }}
                />
              )}
              {networkNames[row.network]}
            </div>
          );
        },
        renderSummaryCell: () => {
          return 'Totals';
        },
      },
      ...WidgetsCommonColumns(),
    ],
    [dates]
  );

  return (
    <Widget
      tableWidget
      widget_key={'channelsProfitAndLoss' + index}
      title={data.title}
      index={index}
      disableLevelFilter
      disableLimit
      disableStatusFilter
      movingButton={movingButton}
      default_filter={data.defaultFilter}
      disableAccountsFilter
      configuration={configuration}
      adsFilter
      adsetFilter
      campaignFilter
      disableDateFilter
      showDatePicker
    >
      <NinjaTable
        columns={columns}
        dataProvider='channelsProfitAndLoss'
        queryParams={{ ...dates }}
        cardProps={{ disable: true }}
        maxHeight={height}
        disableFooter
        tableProps={{ template: 'channels-widget' }}
      />
    </Widget>
  );
};

export default ProfitsAndLossesByChannel;
