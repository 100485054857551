import React from 'react';
import { useModal, useSharedData } from 'store/redux/states/ui/ui.hooks';
import Ninja from '@ninja';
import FormatterButton from 'components/table-formatters/components/FormatterButton';
import { mdiInformation, mdiFilterVariant, mdiContentCopy } from '@mdi/js';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import {
  NameFormatter,
  NetworkFormatter,
  StatusFormatter,
} from 'components/tables/NinjaTable/formatters';
import copyToClipboard from 'common/utils/copyToClipboard';
import AdPreviewFormatter from '../AdPreviewFormatter';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import useQueryParams from 'hooks/useQueryParams';
import AdProcessFormatter from './AdProcessFormatter';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const AdsetFormatter = ({ row, showNetwork = true, editStatus = false }) => {
  const [, setUrlDetailsId] = useSharedData('url_details_id');
  const [modalData, setModal] = useModal('adset_copy');
  const history = useHistory();
  const { setUrlParams } = useQueryParams();
  const { queryParams } = useWidgetDate('global');

  if (!row || !row.adset) {
    return '-';
  }

  const { adset } = row;

  const handleInfoButtonClick = () => {
    console.log('button clicked', adset);
    setUrlDetailsId(adset.id);
  };

  const duplicateAdset = () => {
    setModal({
      adset_id: adset.id,
    });
  };

  const copyName = (e) => {
    e.stopPropagation();

    copyToClipboard(adset.name);
  };

  const handleChange = () => {
    history.push(P.REPORTING.INDEX + queryParams);
    setUrlParams({ group_by: 'ad', adset_ids: row.adset.id });
  };

  const icons = [
    {
      icon: mdiContentCopy,
      onClick: copyName,
      tooltip: 'Copy Name',
      onlyOnHover: true,
      show: adset?.name,
    },
    {
      icon: mdiFilterVariant,
      onClick: handleChange,
      tooltip: 'Reports',
      show: row?.adset?.id ? true : false,
    },

    // {
    //   icon: mdiContentCopy,
    //   onClick: duplicateAdset,
    //   show: adset.network === 'facebook',
    //   tooltip: 'Duplicate Adset',
    // },
    // { icon: mdiInformation, onClick: handleInfoButtonClick, tooltip: 'Details', show: adset.id },
  ];

  return (
    <FormatterButton className='icons-hover-trigger'>
      <div className='flex gap-1 items-center w-full relative'>
        {editStatus ? (
          <AdProcessFormatter row={adset} level='adset' />
        ) : (
          <StatusFormatter row={adset} />
        )}
        {showNetwork && <NetworkFormatter row={adset} />}
        <NameFormatter name={adset.name} />
        <TableIcons color={Ninja.colors.light} icons={icons} />
        <AdPreviewFormatter row={row} level='adset' />
      </div>
    </FormatterButton>
  );
};

export default AdsetFormatter;
