import React, { useEffect, useState } from 'react';
import { Typography, Tabs, Button, Divider } from 'antd';
import Icon from '@mdi/react';
import { mdiFloppy, mdiPencil, mdiFilter, mdiPaletteSwatch, mdiPlus } from '@mdi/js';
import Design from 'modules/DashboardModule/widgets/WidgetConfigurationModal/components/Design';
import { useWidget } from 'components/Widget/useWidget';
import WidgetFilter from '../WidgetFilter';
import { useDashboardLayout } from 'store/redux/states/dashboard/dashboard.hooks';
import { widgetComponents } from 'modules/DashboardModule/widgets/widgetsConfig';
import useQueryParams from 'hooks/useQueryParams';

const Configuration = (props) => {
  const { removeParams } = useQueryParams();
  const { config, updateWidget } = useDashboardLayout();
  const { title, chartConfig, filters, index, showDateFilter } = useWidget();
  const [editableStr, setEditableStr] = useState(title);
  const { Paragraph } = Typography;
  const [activetab, setActivetab] = useState(chartConfig?.load_chart ? 'design' : 'filters');

  useEffect(() => {
    setActivetab(chartConfig?.load_chart ? 'design' : 'filters');
  }, [chartConfig?.load_chart]);

  const items = [
    ...(chartConfig?.load_chart
      ? [
          {
            key: 'design',
            label: 'Design',
            icon: <Icon path={mdiPaletteSwatch} size={1} />,
            children: <Design value={props.value} />,
          },
        ]
      : []),
    {
      key: 'filters',
      label: 'Filters',
      icon: <Icon path={mdiFilter} />,
      children: <WidgetFilter />,
    },
  ];

  const createWidget = () => {
    const filteredFilters = !showDateFilter
      ? Object.fromEntries(
          Object.entries(filters).filter(([key]) => !['date_from', 'date_to'].includes(key))
        )
      : filters;

    const newConfig = {
      key: index,
      title: editableStr,
      position: widgetComponents[index]?.position || { x: 0, y: 0, w: 4, h: 4 },
      defaultFilter: {
        ...filteredFilters,
        ...(!showDateFilter ? {} : { date_range_key: null }),
      },
      show: true,
      config: chartConfig,
    };

    updateWidget([newConfig, ...config]);
    removeParams(['add_widget']);
  };

  const handleTypeChange = (key) => {
    setActivetab(key);
  };

  return (
    <div className='md:flex h-full justify-between gap-4 overflow-hidden md:max-h-[80vh] h-full '>
      {props.children}
      <div className='flex flex-col justify-between gap-2 w-full md:w-1/2 md:mt-0 mt-4'>
        <div className='h-full'>
          <Paragraph
            className='font-semibold flex items-center'
            editable={{
              icon: <Icon path={mdiPencil} className='!text-orange !cursor-pointer flex ml-2' />,
              tooltip: 'Edit Title',
              onChange: setEditableStr,
              enterIcon: <Icon path={mdiFloppy} className='!text-orange !cursor-pointer' />,
            }}
          >
            {editableStr}
          </Paragraph>
          <Tabs className='flex' activeKey={activetab} items={items} onChange={handleTypeChange} />
        </div>
        <div>
          <Divider />
          <Button
            icon={<Icon path={mdiPlus} />}
            type='primary'
            className='ml-auto'
            onClick={createWidget}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Configuration;
