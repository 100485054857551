import React, { useMemo } from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useDashboardTotalSalesQuery } from 'store/redux/apis/dashboard.api';
import Icon from '@mdi/react';
import { mdiCart, mdiDelete, mdiFormatListBulleted, mdiCashMultiple, mdiChartBar } from '@mdi/js';
import Widget from 'components/Widget/Widget';
import PropTypes from 'prop-types';
import { Space, Skeleton, Button, Typography } from 'antd';
import { ValueDotFormatter } from 'components/tables/NinjaTable/formatters';
import { useDashboardLayout } from 'store/redux/states/dashboard/dashboard.hooks';
import DateRangeTag from 'components/Widget/components/DateRangeTag';
import Ninja from '@ninja';
import WidgetCard from 'components/widgets/components/WidgetCard';

const CRMSalesByDays = ({ movingButton, height, data, index }) => {
  const { date_from, date_to, date_range_key } = useWidgetDate();
  const { config, updateWidget } = useDashboardLayout();

  const handleDelete = () => {
    const updatedConfig = config.filter((_, i) => i !== index);
    updateWidget(updatedConfig);
  };

  const {
    data: sales = {
      income: 0,
      quantity: 0,
      aov: 0,
    },
    isLoading,
  } = useDashboardTotalSalesQuery({
    date_from,
    date_to,
    date_range_key,
  });

  const metrics = useMemo(
    () => [
      {
        icon: mdiCashMultiple,
        label: 'Total Profit',
        value: <ValueDotFormatter value={sales.profit || 0} />,
      },
      {
        icon: mdiFormatListBulleted,
        label: 'Quantity',
        value: sales.count || 0,
      },
      {
        icon: mdiChartBar,
        definition: { name: 'AOP', description: 'Average Order Profit' },
        value: (
          <Typography
            variant='h1'
            className={`${sales.aop > 0 ? 'bg-green' : 'bg-red'
              } text-md font-medium text-left sm:text-lg text-white w-fit h-fit rounded-xl px-2`}
          >
            {getFormattedValue(sales.aop)}
          </Typography>
        ),
      },
    ],
    [data, date_from, date_to, date_range_key]
  );

  return (
    <>
      <Widget
        title={data.title}
        icon={<Icon path={mdiCart} className='text-orange' />}
        extra={
          <Space>
            <DateRangeTag />
            <Button
              type='text'
              size='medium'
              onClick={handleDelete}
              icon={<Icon path={mdiDelete} color={Ninja.colors.darkText} />}
            />
            {movingButton}
          </Space>
        }
        bodyStyle={{
          height: height - 60,
        }}
      >
        <Skeleton loading={isLoading} active={true}>
          <div className='grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4 m-auto items-center h-full w-full overflow-x-auto'>
            {metrics.map((metric, index) => (
              <WidgetCard key={index} {...metric} />
            ))}
          </div>
        </Skeleton>
      </Widget>
    </>
  );
};

export default CRMSalesByDays;

CRMSalesByDays.propTypes = {
  data: PropTypes.object.isRequired,
  movingButton: PropTypes.element.isRequired,
  height: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
