import React from 'react';
import { Spin } from 'antd';

const ChartLoading = ({ height = '100%' }) => {
  return (
    <div className='flex items-center justify-center h-full w-full' style={{ height: height }}>
      <Spin size='large' />
    </div>
  );
};

export default ChartLoading;
