import ninjaApi from 'store/redux/apis';
import React, { useEffect } from 'react';
import { Tag, Flex, Spin } from 'antd';
import { StatusFormatter } from 'components/tables/NinjaTable/formatters';
import NetworkIcon from 'components/NetworkIcon/NetworkIcon';

const AdsetsTags = ({ default_adsets, closable = false }) => {
  const [getSearchAdsetQuery, { data = [], isLoading }] = ninjaApi.useLazySearchAdsetQuery();

  useEffect(() => {
    if (default_adsets) {
      getSearchAdsetQuery({
        ids: default_adsets,
      }).unwrap();
    }
  }, [default_adsets]);

  if (isLoading) {
    return <Spin size='small' />;
  }

  if (!data && !default_adsets) {
    return null;
  }

  return (
    <Flex gap='small' wrap>
      {data.map((adset) => (
        <Tag
          key={adset.id}
          closable={closable}
          bordered={false}
          className='font-normal text-[11px] bg-background-light h-7 flex items-center rounded-2xl'
        >
          <div className='flex items-center justify-center w-full gap-1'>
            {adset.status && <StatusFormatter row={adset} title={false} />}
            <NetworkIcon network={adset.network} size={15} />
            {adset.name}
          </div>
        </Tag>
      ))}
    </Flex>
  );
};

export default AdsetsTags;
