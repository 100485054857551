import React, { useEffect, useState } from 'react';
import { Select, Tabs, Typography, Button, Flex, Divider } from 'antd';
import ninjaApi from 'store/redux/apis';
import { useFlowListQuery } from 'modules/CRMModule/api/crm.api';
import Icon from '@mdi/react';
import { mdiPlusCircle } from '@mdi/js';
import useQueryParams from 'hooks/useQueryParams';

const StatusSelectFormatter = (props) => {
  const [getDealStatusListQuery] = ninjaApi.useLazyDealStatusListQuery();
  const { data: source = [] } = useFlowListQuery();
  const { setUrlParams, searchParams } = useQueryParams();
  const { value, onChange, onCancel, multiple = false, ...rest } = props;

  const [options, setOptions] = useState([]);
  const [flow, setFlow] = useState(null);

  useEffect(() => {
    if (source?.data?.length && flow !== 'new_flow') {
      setFlow(source.data[0].id);
    }
  }, [source]);

  useEffect(() => {
    if (flow) {
      getDealStatusListQuery({ deal_status_flow_id: flow })
        .unwrap()
        .then((data) => setOptions(data.data));
    }
  }, [flow, searchParams]);

  const transformedValue = multiple ? value?.map((item) => item.status_id) : value?.status_id;

  const handleChange = (selectedValue) => {
    if (multiple) {
      const statusWithFlow = selectedValue.map((status_id) => ({
        status_id,
        flow_id: value?.find((item) => item.status_id === status_id)?.flow_id || flow,
      }));
      onChange?.(statusWithFlow);
    } else {
      const statusWithFlow = {
        status_id: selectedValue,
        flow_id: value?.flow_id || flow,
      };
      onChange?.(statusWithFlow);
    }
  };

  return (
    <Select
      className='w-full'
      placeholder='Select Status'
      maxTagCount='responsive'
      value={transformedValue}
      dropdownRender={(menu) => (
        <>
          <Button
            size='small'
            type='text'
            className='text-xs'
            icon={<Icon path={mdiPlusCircle} size={0.5} />}
            onClick={() => {
              setUrlParams({
                deal_configuration: true,
                tab: 'statuses',
                flow_id: 'new_flow',
              });
              onCancel?.();
            }}
          >
            Create Flow
          </Button>
          <Tabs
            className='text-xs font-normal ml-2'
            style={{ maxWidth: 350 }}
            activeKey={flow}
            onChange={(id) => setFlow(id)}
            items={[
              ...(source?.data?.map((s) => ({
                label: <Typography className='text-xs'>{s.name}</Typography>,
                key: s.id,
              })) || []),
            ]}
          />
          <div>{menu}</div>
        </>
      )}
      notFoundContent={
        <div className='w-full'>
          {flow === 'new_flow' ? (
            ''
          ) : (
            <Button
              size='small'
              type='primary'
              className='m-x-auto my-2'
              onClick={() => {
                setUrlParams({
                  deal_configuration: true,
                  tab: 'statuses',
                  flow_id: flow,
                });
                onCancel();
              }}
            >
              Create Status
            </Button>
          )}
        </div>
      }
      onChange={handleChange}
      {...rest}
    >
      {options.map((option) => (
        <Select.Option key={option.id} value={option.id}>
          <Flex gap='small' align='center'>
            <div className='!flex !items-center gap-1 py-1'>
              <div
                className='w-2 h-2 rounded-full'
                style={{
                  background: option.color,
                  marginRight: 8,
                }}
              />
              <span className='text-xs font-normal' style={{ color: option.color }}>
                {option.name}
              </span>
            </div>
            <Flex justify='space-between' gap='small' align='center'>
              <Divider type='vertical' style={{ borderColor: '#c3ced5' }} />
              <span className='text-xs font-normal text-color-medium'>{option.group}</span>
            </Flex>
          </Flex>
        </Select.Option>
      ))}
    </Select>
  );
};

export default StatusSelectFormatter;
