import React from 'react';
import { useSharedData } from 'store/redux/states/ui/ui.hooks';
import {
  TrackingStateFormatter,
  StatusFormatter,
  NameFormatter,
  NetworkFormatter,
  NotTrackingFormatter,
} from 'components/tables/NinjaTable/formatters';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';
import FormatterButton from 'components/table-formatters/components/FormatterButton';
import { mdiInformation, mdiContentCopy, mdiFilterVariant } from '@mdi/js';
import copyToClipboard from 'common/utils/copyToClipboard';
import AdPreviewFormatter from '../AdPreviewFormatter';
import AdProcessFormatter from './AdProcessFormatter';
import P from 'router/paths';
import useQueryParams from 'hooks/useQueryParams';
import { useHistory } from 'react-router-dom';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const AdFormatter = ({
  row,
  showNetwork = true,
  editStatus = false,
  enableReportsFilter = true,
}) => {
  const { queryParams } = useWidgetDate('global');
  const [, setUrlDetailsId] = useSharedData('url_details_id');
  const { setUrlParams } = useQueryParams();
  const history = useHistory();

  if (!row || !row.ad) {
    return '-';
  }

  const { ad } = row;

  const handleInfoButtonClick = () => {
    console.log('button clicked', row);
    setUrlDetailsId(ad.id);
  };

  const copyName = (e) => {
    e.stopPropagation();

    copyToClipboard(ad.name);
  };

  const handleChange = () => {
    history.push(P.REPORTING.INDEX + queryParams);
    setUrlParams({ group_by: 'ad', ad_ids: row.ad.id });
  };

  const icons = [
    {
      icon: mdiContentCopy,
      onClick: copyName,
      tooltip: 'Copy Name',
      onlyOnHover: true,
      show: ad?.name,
    },
    {
      icon: mdiFilterVariant,
      onClick: handleChange,
      tooltip: 'Reports',
      show: row?.ad?.id && enableReportsFilter ? true : false,
    },
    // { icon: mdiInformation, onClick: handleInfoButtonClick, tooltip: 'Details' },
  ];

  return (
    <FormatterButton className='icons-hover-trigger'>
      <div className='flex gap-2 items-center w-full relative'>
        <div className='flex items-center gap-1'>
          {editStatus ? <AdProcessFormatter row={ad} level='ad' /> : <StatusFormatter row={ad} />}
          {showNetwork && <NetworkFormatter row={ad} />}
          {ad.hasOwnProperty('is_tracked') && <NotTrackingFormatter data={ad} />}
          <TrackingStateFormatter isTracked={ad?.is_tracked} />
        </div>
        <NameFormatter name={ad?.name} />
        <TableIcons color={Ninja.colors.light} icons={icons} />
        <AdPreviewFormatter row={row} level='ad' />
      </div>
    </FormatterButton>
  );
};

export default AdFormatter;
