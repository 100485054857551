import { mdiCloseCircle } from '@mdi/js';
import React from 'react';
import Ninja from '@ninja';
import { Popover } from 'antd';
import Icon from '@mdi/react';
import UrlHelper from 'common/utils/url-helper';

const trackingLinks = {
  meta: 'docs/tracking/meta-ads',
  facebook: 'docs/tracking/meta-ads',
  google: 'docs/tracking/google-ads',
  tiktok: 'docs/tracking/tiktok-ads',
  bing: 'docs/tracking/bing-ads',
};

const NotTrackingFormatter = ({ data }) => {
  if (data?.is_tracked) return null;

  const link = trackingLinks[data?.network?.toLowerCase()] || 'docs/tracking/';

  return (
    <Popover
      content={
        <div className='flex items-center justify-between gap-2'>
          Attribution is not set up
          <Icon path={mdiCloseCircle} color={Ninja.colors.error} />
        </div>
      }
      title={
        <a target='_blank' href={UrlHelper.docs(link)}>
          Learn how set up attribution.
        </a>
      }
      className='cursor-pointer'
    >
      <Icon path={mdiCloseCircle} color={Ninja.colors.error} size={0.6} />
    </Popover>
  );
};

export default NotTrackingFormatter;
