import React, { useEffect } from 'react';
import { useWidget } from 'components/Widget/useWidget';
import ChartBody from 'modules/DashboardModule/charts/components/ChartBody';
import ChartLoading from 'components/charts/components/ChartLoading';
import NoResultFound from 'components/charts/components/NoResultFound';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { getMin7DaysRange } from 'components/DateRangePicker/components/disableDateRange';
import ninjaApi from 'store/redux/apis/ninja.api';

const TikTokChartContent = ({ height }) => {
  const { filters, widget_key, chartConfig } = useWidget();
  const { date_from, date_to, setDate } = useWidgetDate(widget_key);

  const [getNetworksReportsQuery, { data: tiktok = {}, isLoading }] =
    ninjaApi.useLazyNetworksReportsQuery();

  useEffect(() => {
    let adjustedDateFrom = date_from;
    let adjustedDateTo = date_to;

    if (chartConfig?.load_chart) {
      ({ date_from: adjustedDateFrom, date_to: adjustedDateTo } = getMin7DaysRange(
        date_from,
        date_to
      ));
    }

    const params = {
      ...filters,
      date_from: adjustedDateFrom,
      date_to: adjustedDateTo,
    };

    if (chartConfig?.load_chart) {
      params.group_by = 'date';
    }

    if (filters?.date_from) {
      getNetworksReportsQuery(params)
        .unwrap()
        .then(() => {
          setDate({
            date_from: adjustedDateFrom,
            date_to: adjustedDateTo,
          });
        });
    }
  }, [filters, chartConfig?.load_chart]);

  if (isLoading) {
    return <ChartLoading height={height} />;
  }

  if (!tiktok?.length) {
    return <NoResultFound height={height} />;
  }

  return <ChartBody height={height} data={tiktok} />;
};

export default TikTokChartContent;
