import React, { useState, useMemo, useEffect } from 'react';
import { Select, Space, Segmented, Checkbox, Divider, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import useIntegrationStores from 'modules/apps/api/useIntegrationStores';

const StoreSelect = (props) => {
  const {
    handleChanges,
    value = [],
    integration = 'all',
    disableSelectAll,
    disableStoreSwitch,
  } = props;
  const [selectedApp, setSelectedApp] = useState(integration);
  const { shopify, woocommerce } = useIntegrationStores();

  useEffect(() => {
    setSelectedApp(integration);
  }, [integration]);

  const stores = useMemo(() => {
    if (integration === 'all') {
      return [...shopify, ...woocommerce];
    } else if (integration === 'shopify') {
      return shopify;
    } else if (integration === 'woocommerce') {
      return woocommerce;
    }
    return integration || [];
  }, [shopify, woocommerce, integration]);

  const options = stores.filter((opt) => selectedApp === 'all' || opt.type === selectedApp);
  const handleChange = (value) => {
    handleChanges?.(value);
  };

  const handleIntegrationChange = (app) => {
    setSelectedApp(app);
  };

  const selectAllChanged = (e) => {
    if (e.target.checked) {
      handleChange?.(options.map((opt) => opt.id));
    } else {
      handleChange?.([]);
    }
  };

  const allSelected = options?.length === value?.length;

  return (
    <Select
      value={value}
      className='w-full'
      placeholder='Select Store'
      onChange={handleChange}
      mode='multiple'
      maxTagCount='responsive'
      dropdownRender={(menu) => (
        <>
          <div className='flex items-center justify-between gap-2'>
            {!disableSelectAll && (
              <Tooltip
                placement='right'
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                title={allSelected ? 'Clear All' : `Select All ${options.length}`}
              >
                <Checkbox
                  checked={allSelected}
                  onChange={selectAllChanged}
                  className='text-xs font-normal'
                />
              </Tooltip>
            )}
            {!disableStoreSwitch && (
              <Segmented
                value={selectedApp}
                onChange={handleIntegrationChange}
                options={[
                  {
                    label: <span className='text-xs'>All</span>,
                    value: 'all',
                  },
                  {
                    label: <img src='/icons/integrations/shopify.svg' className='w-5' />,
                    value: 'shopify',
                  },
                  {
                    label: <img src='/icons/integrations/woocommerce.svg' className='w-5' />,
                    value: 'woocommerce',
                  },
                ]}
              />
            )}
          </div>
          <Divider className='my-2' />
          {menu}
        </>
      )}
      {...props}
    >
      {options.map((opt) => (
        <Select.Option value={opt.id} key={opt.id} label={opt.name}>
          <Space>
            <span role='img' aria-label={opt.name}>
              <img src={`/icons/integrations/${opt.type}.svg`} className='w-5' />
            </span>
            {opt.name}
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default StoreSelect;

StoreSelect.propTypes = {
  handleChanges: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};
