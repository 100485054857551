import { makeStyles } from '@material-ui/core';
import React from 'react';
// import { useAdCreationNetworks } from 'store/redux/states/adform/ad-form.hooks';

const NetworkIndicators = (props) => {
  const { indicators = [] } = props;
  // const { networks } = useAdCreationNetworks();
  const classes = useStyles();

  return (
    <div className='flex justify-around items-center height-[25px] p-[2px] border border-color-xlight rounded-full'>
      {/* {indicators.map((ind) => {
        return (
          networks[ind] && (
            <div className={classes.iconButton} key={ind}>
              <img src={`/images/${ind}.png`} alt='' className={classes.icon} />
            </div>
          )
        );
      })} */}
    </div>
  );
};

export default NetworkIndicators;

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    // marginRight: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-around',
    border: `1px solid ${theme.colors.xlight}`,
    height: 25,
    padding: theme.spacing(0.25),
    borderRadius: 100,
  },
  iconButton: {
    padding: 2,
    borderRadius: 100,
    width: 20,
  },
  icon: {
    objectFit: 'contain',
    height: '15px',
  },
}));
