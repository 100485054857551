import React, { useState, useEffect } from 'react';
import { DatePicker, Checkbox, Button, Flex } from 'antd';
import dayjs from 'dayjs';
import { mdiCalendarMonth } from '@mdi/js';
import Icon from '@mdi/react';
import './style.css';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import useQueryParams from 'hooks/useQueryParams';
import { staticRanges } from './components/staticRanges';

const DateRangePicker = (props) => {
  const { RangePicker } = DatePicker;
  const {
    value = [],
    date_key = 'global',
    onChange,
    globalDate = true,
    placeholder = ['Start date', 'End date'],
    variant = 'filled',
    showExpandDays = false,
    icon = true,
    disabledDate,
    ...rest
  } = props;
  const { date_from, date_to, setDate } = useWidgetDate(date_key);
  const { setUrlParams } = useQueryParams();
  const [changeGlobal, setChangeGlobal] = useState(globalDate);
  const [formattedValue, setFormattedValue] = useState([
    date_from ? dayjs(date_from) : null,
    date_to ? dayjs(date_to) : null,
  ]);

  useEffect(() => {
    setFormattedValue([date_from ? dayjs(date_from) : null, date_to ? dayjs(date_to) : null]);
  }, [date_from, date_to]);

  useEffect(() => {
    if (date_from && date_to) {
      setFormattedValue([dayjs(date_from), dayjs(date_to)]);
    } else {
      setFormattedValue([null, null]);
    }
  }, [date_from, date_to]);

  const onRangeChange = (dates, dateStrings) => {
    const formattedDates = dates ? dates.map((date) => date?.format('YYYY-MM-DD')) : [null, null];

    const matchedPreset = staticRanges.find(
      (preset) =>
        preset.value[0].format('YYYY-MM-DD') === formattedDates[0] &&
        preset.value[1].format('YYYY-MM-DD') === formattedDates[1]
    );

    const dateLabel = matchedPreset ? matchedPreset.label : null;
    const dateKey = matchedPreset ? matchedPreset.key : null;

    setDate({
      date_from: formattedDates[0],
      date_to: formattedDates[1],
      change_global: changeGlobal,
      date_key: date_key,
      label: dateLabel,
      date_range_key: dateKey,
    });

    if (changeGlobal) {
      setUrlParams({ date_from: formattedDates[0], date_to: formattedDates[1] });
    }
  };

  const adjustDateRange = (type) => {
    if (!formattedValue[0] || !formattedValue[1]) return;

    let newStartDate = formattedValue[0];
    let newEndDate = formattedValue[1];

    if (type === 'subtract') {
      newStartDate = newStartDate.subtract(10, 'days');
    } else if (type === 'add') {
      newEndDate = newEndDate.add(10, 'days');
    }

    setFormattedValue([newStartDate, newEndDate]);
    onRangeChange([newStartDate, newEndDate]);
  };

  return (
    <Flex align='center' gap='small' wrap={true}>
      {showExpandDays && (
        <Button size='medium' shape='round' onClick={() => adjustDateRange('subtract')}>
          -10 Days
        </Button>
      )}
      <RangePicker
        size='small'
        placement='bottomRight'
        format='MMM-DD'
        variant={variant}
        className='!text-sm !font-semibold w-full max-w-fit'
        {...rest}
        value={formattedValue}
        presets={staticRanges}
        disabledDate={disabledDate}
        onChange={onRangeChange}
        separator='-'
        suffixIcon={icon && <Icon className='text-orange' path={mdiCalendarMonth} />}
        renderExtraFooter={() => (
          <Checkbox
            className='px-2 text-sm font-normal'
            onChange={(e) => setChangeGlobal(e.target.checked)}
            checked={changeGlobal}
          >
            Apply Globally
          </Checkbox>
        )}
      />
      {showExpandDays && (
        <Button size='medium' shape='round' onClick={() => adjustDateRange('add')}>
          +10 Days
        </Button>
      )}
    </Flex>
  );
};

export default DateRangePicker;
