import React from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useNetworksReportsQuery } from 'store/redux/apis/dashboard.api';
import Widget from 'components/Widget/Widget';
import { Skeleton } from 'antd';
import NetworkWidget from 'components/widgets/components/NetworkWidget';

const NetworksData = ({ movingButton, height, data, configuration, index }) => {
  const { date_from, date_to, date_range_key } = useWidgetDate('meta_widget' + index);

  const filters = {
    date_from,
    date_to,
    date_range_key,
    ...data.defaultFilter,
  };

  const { data: facebook = {}, isLoading } = useNetworksReportsQuery(filters);

  return (
    <Widget
      title={data.title}
      icon={<img src='/icons/networks/facebook.svg' className='w-5 h-5' />}
      movingButton={movingButton}
      widget_key={'meta_widget' + index}
      bodyStyle={{
        height: height - 58,
      }}
      configuration={configuration}
      disableLimit
      disableStatusFilter
      disableLevelFilter
      network='facebook'
      networkSwitch={false}
      default_filter={data?.defaultFilter}
      index={index}
      adsFilter
      adsetFilter
      campaignFilter
      disableDateFilter
      showDatePicker
    >
      <Skeleton loading={isLoading} active={true}>
        <NetworkWidget data={facebook?.facebook} network='facebook' />
      </Skeleton>
    </Widget>
  );
};

export default NetworksData;
