import React, { useEffect } from 'react';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useWidget } from 'components/Widget/useWidget';
import ninjaApi from 'store/redux/apis/ninja.api';
import { metricsList } from 'modules/DashboardModule/charts/components/metricsList';
import ChartBody from 'modules/DashboardModule/charts/components/ChartBody';
import ChartLoading from 'components/charts/components/ChartLoading';
import NoResultFound from 'components/charts/components/NoResultFound';
import { getMin7DaysRange } from 'components/DateRangePicker/components/disableDateRange';

const PnLByDaysChartBody = ({ height }) => {
  const { filters, chartConfig, widget_key } = useWidget();
  const { date_from, date_to, setDate } = useWidgetDate(widget_key);

  const [getDashboardDailyStatsQuery, { data: list = {}, isLoading }] =
    ninjaApi.useLazyDashboardDailyStatsQuery();

  useEffect(() => {
    let adjustedDateFrom = date_from;
    let adjustedDateTo = date_to;

    if (chartConfig?.load_chart) {
      ({ date_from: adjustedDateFrom, date_to: adjustedDateTo } = getMin7DaysRange(
        date_from,
        date_to
      ));
    }

    const params = {
      ...filters,
      date_from: adjustedDateFrom,
      date_to: adjustedDateTo,
    };

    if (chartConfig?.load_chart) {
      params.group_by = 'date';
    }

    if (filters?.date_from) {
      getDashboardDailyStatsQuery(params)
        .unwrap()
        .then(() => {
          setDate({
            date_from: adjustedDateFrom,
            date_to: adjustedDateTo,
          });
        });
    }
  }, [filters, chartConfig?.load_chart, date_to]);

  if (isLoading) {
    return <ChartLoading height={height} />;
  }

  if (!list?.data?.length) {
    return <NoResultFound height={height} />;
  }

  return <ChartBody height={height} metricsList={metricsList} data={list.data} />;
};

export default PnLByDaysChartBody;
