import React, { useState, useEffect } from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
  CartesianGrid,
} from 'recharts';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useWidget } from 'components/Widget/useWidget';
import ninjaApi from 'store/redux/apis/ninja.api';
import { metricsList } from 'modules/DashboardModule/charts/components/metricsList';
import { MetricItem } from 'components/charts/components/ChartTooltip';
import ChartLoading from 'components/charts/components/ChartLoading';
import NoResultFound from 'components/charts/components/NoResultFound';

const PnLByChannelsChartBody = ({ height }) => {
  const { filters, chartConfig, widget_key } = useWidget();
  const { dates } = useWidgetDate(widget_key);

  const [visibleMetrics, setVisibleMetrics] = useState(chartConfig?.metrics);

  useEffect(() => {
    setVisibleMetrics(chartConfig.metrics);
  }, [chartConfig]);

  const [getChannelsProfitAndLossQuery, { data: list = {}, isLoading }] =
    ninjaApi.useLazyChannelsProfitAndLossQuery();

  useEffect(() => {
    if (filters) {
      getChannelsProfitAndLossQuery(filters).unwrap();
    }
  }, [filters, dates]);

  if (isLoading) {
    return <ChartLoading height={height} />;
  }

  if (!list?.data?.length) {
    return <NoResultFound height={height} />;
  }

  const renderBarsAndLines = () => {
    return Object.keys(metricsList).map((key) => {
      const { color, name, type, dashed } = metricsList[key];

      if (type === 'line') {
        return (
          <Line
            key={key}
            type='monotone'
            dataKey={key}
            stroke={color}
            fill={color}
            name={name}
            hide={!visibleMetrics[key]?.show}
            yAxisId='right'
            strokeWidth={2}
            strokeDasharray={dashed && '10 10'}
            isAnimationActive
            animationDuration={800}
          />
        );
      }
      return (
        <Bar
          key={key}
          stackId={chartConfig.type === 'stacked' ? 'a' : undefined}
          dataKey={key}
          fill={color}
          name={name}
          hide={!visibleMetrics[key]?.show}
          isAnimationActive
          animationDuration={800}
          radius={[3, 3, 0, 0]}
        />
      );
    });
  };

  const networkNames = {
    facebook: {
      title: 'Meta',
      icon: <img src='/icons/networks/meta.svg' className='w-4 h-4' />,
    },
    google: {
      title: 'Google',
      icon: <img src='/icons/networks/google.svg' className='w-4 h-4' />,
    },
    bing: {
      title: 'Bing',
      icon: <img src='/icons/networks/bing.svg' className='w-4 h-4' />,
    },
    tiktok: {
      title: 'tiktok',
      icon: <img src='/icons/networks/tiktok.svg' className='w-4 h-4' />,
    },
    untracked: {
      title: 'Non Tracked',
    },
    custom_url: { title: 'Custom URLs' },
    organic: { title: 'Custom URLs' },
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (!active || !payload?.length) return null;

    const gridColsClass = payload.length > 8 ? 'grid-cols-2' : 'grid-cols-1';

    return (
      <div
        className='p-2 rounded shadow-xl gap-2 grid'
        style={{ background: 'rgba(255,255,255,0.95' }}
      >
        <div className='font-semibold text-md'>
          {networkNames[label]?.icon || networkNames[label]?.title}
        </div>
        <div className={`grid w-full gap-x-4 ${gridColsClass}`}>
          {payload.map((entry) => {
            return <MetricItem metricsList={metricsList} entry={entry} />;
          })}
        </div>
      </div>
    );
  };

  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    const network = payload.value;
    const networkData = networkNames[network];

    return (
      <g transform={`translate(${x},${y})`}>
        {networkData?.icon ? (
          <image href={networkData.icon.props.src} width='20' height='20' x='-10' y='-5' />
        ) : (
          <text x='-10' y='0' dy='10' textAnchor='middle' fill='#666'>
            {networkData?.title || 'Custom URLs'}
          </text>
        )}
      </g>
    );
  };

  return (
    <ResponsiveContainer height={height} width='100%'>
      <ComposedChart
        margin={{
          right: chartConfig.showYAxis ? -40 : 0,
          left: chartConfig.showXAxis ? -20 : 0,
        }}
        data={list.data}
        key={chartConfig.type === 'stacked' ? 'stacked' : 'grouped'}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='network' tick={<CustomXAxisTick />} />

        <YAxis hide={!chartConfig.showYAxis} />
        <YAxis hide={!chartConfig.showYAxis} yAxisId='right' orientation='right' />
        <Tooltip content={<CustomTooltip />} />
        {renderBarsAndLines()}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default PnLByChannelsChartBody;
