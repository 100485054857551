import React, { useEffect, useState } from 'react';
import { Alert, Checkbox, Typography } from 'antd';
import clsx from 'clsx';
import UrlHelper from 'common/utils/url-helper';

const MetaAccountSetup = ({ onChange }) => {
  const [create, setCreate] = useState({
    pixel: true,
    auto_tracking: true,
  });

  const handleChange = (e) => {
    setCreate((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  useEffect(() => {
    onChange && onChange(create);
  }, [create]);

  return (
    <div>
      <Typography.Paragraph>
        MyDataNinja recomends some settings in your account work properly and well optimized, you
        can choose which steps to follow to optimize your account for MyDataNinja
      </Typography.Paragraph>
      <div className='flex flex-col'>
        <div className='flex gap-2 items-center'>
          <Checkbox onChange={handleChange} name='pixel' checked={create.pixel} />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.pixel,
            })}
          >
            Create pixel script if not exists.
            <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link>
          </Typography.Paragraph>
        </div>
        <div className='flex gap-2 items-center text-left'>
          <Checkbox
            onChange={handleChange}
            name='standard_conversion'
            checked={create.standard_conversion}
          />
          <Typography.Paragraph className={clsx('!mb-0 mt-1')}>
            Create default pixel and standard conversion events (If not already created).
            <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link>
          </Typography.Paragraph>
        </div>
        <div className='flex gap-2 items-center text-left mt-4'>
          {/* <Checkbox disabled name='auto_tracking' checked={false} /> */}
          <Alert
            message='Auto tracking is not avialable for meta '
            type='warning'
            showIcon
            className='w-full'
            description={
              <Typography.Link href={UrlHelper.docs('/docs/tracking/meta-ads')} target='_blank'>
                {' '}
                Learn More
              </Typography.Link>
            }
          />
        </div>
      </div>
    </div>
  );
};

MetaAccountSetup.propTypes = {};

export default MetaAccountSetup;
