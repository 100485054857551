import React, { useState } from 'react';
import { useWidget } from 'components/Widget/useWidget';
import { Flex, Checkbox, ColorPicker, Typography, Collapse } from 'antd';

const CheckboxGroup = Checkbox.Group;

const Design = () => {
  const { metricsList, chartConfig, setChartConfig } = useWidget();

  const [metrics, setMetrics] = useState(
    metricsList &&
      Object.fromEntries(
        Object.entries(metricsList).map(([key, { color, name, show, type = 'bar' }]) => [
          key,
          { color, name, show: show, type },
        ])
      )
  );

  const [checkedKeys, setCheckedKeys] = useState(
    metrics && Object.keys(metrics).filter((key) => metrics[key].show)
  );

  const handleChange = (checkedValues) => {
    setCheckedKeys(checkedValues);
    const updatedMetrics = Object.fromEntries(
      Object.entries(metrics).map(([key, value]) => [
        key,
        { ...value, show: checkedValues.includes(key) },
      ])
    );
    setMetrics(updatedMetrics);
    const filteredMetrics = Object.fromEntries(
      Object.entries(updatedMetrics).filter(([key, value]) => value.show)
    );
    setChartConfig({ ...chartConfig, metrics: filteredMetrics });
  };

  const handleColorChange = (key, color) => {
    const updatedMetrics = {
      ...metrics,
      [key]: { ...metrics[key], color },
    };
    setMetrics(updatedMetrics);
    const filteredMetrics = Object.fromEntries(
      Object.entries(updatedMetrics).filter(([key, value]) => value.show)
    );
    setChartConfig({ ...chartConfig, metrics: filteredMetrics });
  };

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    const newCheckedKeys = isChecked ? Object.keys(metrics) : [];
    setCheckedKeys(newCheckedKeys);
    const updatedMetrics = Object.fromEntries(
      Object.entries(metrics).map(([key, value]) => [key, { ...value, show: isChecked }])
    );
    setMetrics(updatedMetrics);
    setChartConfig({ ...chartConfig, metrics: updatedMetrics });
  };

  const handleChangeX = (value) => {
    setChartConfig({ ...chartConfig, showXAxis: value.target.checked });
  };

  const handleChangeY = (value) => {
    setChartConfig({ ...chartConfig, showYAxis: value.target.checked });
  };

  const handleAreaColor = (key, color) => {
    setChartConfig((prevConfig) => ({
      ...prevConfig,
      areaConfig: {
        ...prevConfig.areaConfig,
        [key]: color,
      },
    }));
  };

  return (
    <div className='my-2'>
      {!metrics && chartConfig?.load_chart && (
        <div className='grid gap-3 mt-4'>
          <Flex align='center' gap='small'>
            <ColorPicker
              size='small'
              value={chartConfig?.areaConfig?.strokeColor || '#536A8B '}
              onChange={(color) => handleAreaColor('strokeColor', color.toHexString())}
            />
            <Typography className='font-normal text-sm'>Stroke Color</Typography>
          </Flex>
          <Flex align='center' gap='small'>
            <ColorPicker
              size='small'
              value={chartConfig?.areaConfig?.fillColor || '#F0F6FF '}
              onChange={(color) => handleAreaColor('fillColor', color.toHexString())}
            />
            <Typography className='font-normal text-sm'>Fill Color</Typography>
          </Flex>
        </div>
      )}

      {(chartConfig?.load_chart || metricsList) && (
        <div className='grid gap-2 my-4'>
          <Checkbox
            className='font-normal'
            defaultChecked={chartConfig.showXAxis}
            onChange={handleChangeX}
          >
            Show X-Axis Labels
          </Checkbox>
          <Checkbox
            className='font-normal'
            defaultChecked={chartConfig.showYAxis}
            onChange={handleChangeY}
          >
            Show Y-Axis Labels
          </Checkbox>
        </div>
      )}
      {metricsList && (
        <Collapse
          bordered={true}
          size='small'
          className='!rounded-md border border-solid border-color-xlight  my-2 text-sm font-normal'
          items={[
            {
              key: '2',
              label: 'Data',
              children: (
                <div className=''>
                  <Checkbox
                    className='text-sm font-normal'
                    onChange={handleCheckAll}
                    checked={checkedKeys.length === Object.keys(metrics).length}
                  >
                    Check all
                  </Checkbox>
                  <CheckboxGroup value={checkedKeys} onChange={handleChange} className='block mt-2'>
                    {Object.keys(metrics).map((key) => (
                      <div key={key} className='flex items-center gap-2 mt-1'>
                        <Checkbox value={key} />
                        <ColorPicker
                          size='small'
                          value={metrics[key].color}
                          onChange={(color) => handleColorChange(key, color.toHexString())}
                        />
                        <Typography className='font-normal text-sm'>{metrics[key].name}</Typography>
                      </div>
                    ))}
                  </CheckboxGroup>
                </div>
              ),
            },
          ]}
        />
      )}
    </div>
  );
};

export default Design;
