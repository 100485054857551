import React from 'react';
import Widget from 'components/Widget/Widget';
import WooCommerceChartContent from './components/WooCommerceChartContent';
import { metricsList as defaultMetricsList } from '../components/metricsList';
import { disabled7DaysDate } from 'components/DateRangePicker/components/disableDateRange';

const WooCommerceReportsChart = (props) => {
  const { data, index, movingButton, height, configuration } = props;

  const metricsList = {
    avg_order_value: { color: '#3B6F3A', name: 'Avg. Order Value', currency: true },
    cac: { color: '#5A8F53', name: 'CAC', currency: true },
    avg_profit_per_order: { color: '#7A9E5E', name: 'Avg. Profit Per Order', currency: true },
    new_customer_orders: { color: '#A0C68F', name: 'New Customer Orders' },
    orders: { color: 'rgb(145, 199, 22)', name: 'Orders' },
    cogs: { color: '#9DC08B', name: 'COGS', currency: true },
  };

  return (
    <Widget
      className='h-ful'
      tableWidget
      widget_key={'woocommerce_widget' + index}
      integrationType='woocommerce'
      title={data.title}
      showIntegrationFilters
      index={index}
      disableLimit
      disableStatusFilter
      disableAccountsFilter
      disableLevelFilter
      movingButton={movingButton}
      data={data}
      load_chart={true}
      default_filter={data?.defaultFilter}
      icon={<img src='/icons/integrations/woocommerce.svg' className='w-7 h-7 ml-2' />}
      configuration={configuration}
      metricsList={{ ...defaultMetricsList, ...metricsList }}
      disabledDate={disabled7DaysDate}
    >
      <WooCommerceChartContent height={height - 50} />
    </Widget>
  );
};

export default WooCommerceReportsChart;
