import NetworkAccountMainMenu from 'modules/network-accounts/menus/NetworkAccountMainMenu';
import CustomUrlMainMenu from 'modules/UrlModule/menus/CustomUrlMainMenu';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PostbacksMainMenu from 'modules/PostbacksModule/menus/PostbacksMainMenu';
import ReportMainMenu from 'modules/report/menus/ReportMainMenu';
import SalesMainMenu from 'modules/sales/menus/SalesMainMenu';
import ReportingMainMenu from 'modules/ReportingModule/menus/ReportingMainMenu';
import CrmMainMenu from 'modules/CRMModule/menus/CrmMainMenu';

const SecondaryMenu = ({ component }) => {
  return (
    <Switch>
      <Route path='/accounts' component={NetworkAccountMainMenu} />
      <Route path='/report' render={() => <ReportMainMenu dateRangeComponent={component} />} />
      <Route
        path='/postbacks'
        render={() => <PostbacksMainMenu dateRangeComponent={component} />}
      />
      <Route path='/url' render={() => <CustomUrlMainMenu dateRangeComponent={component} />} />
      <Route path='/sales' render={() => <SalesMainMenu dateRangeComponent={component} />} />
      <Route
        path='/reporting'
        render={() => <ReportingMainMenu dateRangeComponent={component} />}
      />
      <Route
        path='/reporting/creative'
        render={() => <ReportingMainMenu dateRangeComponent={component} />}
      />
      <Route path='/crm' render={() => <CrmMainMenu dateRangeComponent={component} />} />
    </Switch>
  );
};

export default SecondaryMenu;
