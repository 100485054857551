import React from 'react';
import { Divider } from 'antd';
import SingleInfoRow from 'components/ReportInfo/SingleInfoRow';

const AdSpendInfo = ({ data, isLoading }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data?.by_networks) {
    return 'No Network data';
  }

  return (
    <div className='flex flex-col w-[200px] '>
      {Object.entries(data.by_networks).map(([network, spent]) => (
        <SingleInfoRow
          key={network}
          tooltip={`Ad spent of ${network} ads`}
          label={<img src={`/icons/networks/${network}.svg`} className='text-orange w-5 h-5' />}
          value={spent}
          color='n'
        />
      ))}
      <Divider />
      <SingleInfoRow label='Total' value={data.total} />
    </div>
  );
};

export default AdSpendInfo;
