import React from 'react';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';
import { Pagination } from 'antd';
import useQueryParams from 'hooks/useQueryParams';

const NinjaTableFooter = (props) => {
  const {
    showSizeChanger = true,
    readOnlyPagination = false,
    changeGlobally = false,
  } = props;
  const { pagination, setPagination } = useNinjaTable();
  const { setUrlParams } = useQueryParams();

  const onPageChange = (current, size) => {
    if (changeGlobally) {
      setUrlParams({ per_page: size, page: current });
    }
    setPagination({ ...pagination, per_page: size, page: current });
  };

  return (
    <div className='px-4 py-4 nnj-grid-footer'>
      <Pagination
        responsive
        showSizeChanger={showSizeChanger && pagination.total > 10}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        pageSize={pagination.per_page}
        total={pagination.total}
        current={pagination.page}
        onChange={onPageChange}
        simple={readOnlyPagination}
        showLessItems
        pageSizeOptions={['10', '20', '25', '50', '100', '250', '500']}
      />
    </div>
  );
};

export default NinjaTableFooter;
