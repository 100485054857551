import { mdiCheckBold } from '@mdi/js';
import React from 'react';
import Ninja from '@ninja';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';

const TrackingStateFormatter = ({ isTracked }) => {
  if (!isTracked) {
    return null;
  }

  return (
    <TableIcons
      triggerHoverOnCell
      icons={[
        {
          color: Ninja.colors.success,
          icon: mdiCheckBold,
          tooltip: 'Attribution is set up.',
          size: '0.9rem',
        },
      ]}
    />
  );
};

export default TrackingStateFormatter;
