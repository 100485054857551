import React, { useState, useEffect, useCallback } from 'react';
import { Space, Checkbox, Button } from 'antd';
import { useWidget } from './useWidget';
import NetworkAccountSelect from 'components/Selects/NetworkAccountSelect/NetworkAccountSelect';
import StatusSelect from 'components/Selects/StatusSelect';
import StoreSelect from 'components/Selects/StoreSelect';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import DateRangePicker from 'components/DateRangePicker';
import Alert from 'components/Dialogs/Alert';
import AdSelect from 'components/Selects/AdSelect';
import AdsetNameSelect from 'components/Selects/AdsetNameSelect';
import CampaignSelect from 'components/Selects/CampaignSelect';
import IntegrationsSelect from 'components/Selects/IntegrationsSelect';

const WidgetFilter = ({}) => {
  const {
    showIntegrationFilters = false,
    disableStatusFilter,
    disableAccountsFilter,
    default_filter,
    disableDateFilter = false,
    widget_key,
    filters,
    setFilters,
    runFilter,
    networkSwitch,
    network,
    showDateFilter,
    setShowDateFilter,
    adsFilter,
    adsetFilter,
    campaignFilter,
    disabledDate,
    chartConfig,
    integrationsSelect = false,
    DisableFilterButton,
  } = useWidget();
  const { setDate } = useWidgetDate(widget_key);
  const {
    date_from: globalDateFrom,
    date_to: globalDateTo,
    date_range_key: globalKey,
  } = useWidgetDate('global');
  const [tempFilters, setTempFilters] = useState(filters);

  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  const handleFilters = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resetFilter = useCallback(() => {
    setTempFilters({ ...default_filter });
    setFilters({ ...default_filter });

    if (default_filter.date_from || default_filter.date_range_key) {
      setDate({
        date_from: default_filter.date_from,
        date_to: default_filter.date_to,
        date_range_key: default_filter.date_range_key,
      });
    } else {
      setDate({
        date_from: globalDateFrom,
        date_to: globalDateTo,
        date_range_key: globalKey,
      });
    }
    runFilter();
  }, [default_filter, setFilters, setDate, runFilter]);

  return (
    <Space direction='vertical' className='w-full my-2'>
      {!disableDateFilter && (
        <>
          <Checkbox
            defaultChecked={showDateFilter}
            onChange={(e) => setShowDateFilter(e.target.checked)}
            className='font-normal'
          >
            Apply specific date
          </Checkbox>
          {showDateFilter && (
            <div className='grid gap-2 items-center w-full my-2'>
              <DateRangePicker
                disabledDate={chartConfig?.load_chart && disabledDate}
                className='w-full'
                variant='outlined'
                size='medium'
                date_key={widget_key}
                globalDate={false}
              />
              <Alert
                type='warning'
                showIcon
                description={
                  <span className='tex-xs'>
                    When enabled date, widget will always use this selected date.
                  </span>
                }
              />
            </div>
          )}
        </>
      )}

      {adsFilter && (
        <AdSelect
          networkSwitch={networkSwitch}
          network={network}
          className='w-full'
          multiple
          value={tempFilters?.ad_ids ?? []}
          onChange={(ids) => {
            handleFilters('ad_ids', ids);
          }}
        />
      )}
      {adsetFilter && (
        <AdsetNameSelect
          networkSwitch={networkSwitch}
          network={network}
          className='w-full'
          multiple
          value={tempFilters?.adset_ids ?? []}
          onChange={(ids) => {
            handleFilters('adset_ids', ids);
          }}
        />
      )}
      {campaignFilter && (
        <CampaignSelect
          networkSwitch={networkSwitch}
          network={network}
          className='w-full'
          multiple
          value={tempFilters?.campaign_ids ?? []}
          onChange={(ids) => {
            handleFilters('campaign_ids', ids);
          }}
        />
      )}
      {!disableStatusFilter && (
        <div className='col-span-2 md:col-span-1 h-full'>
          <StatusSelect
            value={tempFilters.statuses}
            onChange={(statuses) => {
              handleFilters('statuses', statuses);
            }}
            className='h-full'
          />
        </div>
      )}
      {!disableAccountsFilter && (
        <NetworkAccountSelect
          networkSwitch={networkSwitch}
          network={network}
          value={tempFilters?.network_account_ids}
          onChange={(account_ids) => {
            handleFilters('network_account_ids', account_ids);
          }}
          maxTagCount={10}
        />
      )}
      {integrationsSelect && (
        <IntegrationsSelect
          mode={false}
          value={tempFilters?.integration_type || []}
          onChange={(val) => {
            handleFilters('integration_type', val);
          }}
        />
      )}
      {(integrationsSelect && filters?.integration_type) || showIntegrationFilters ? (
        <StoreSelect
          disableStoreSwitch
          integration={filters?.integration || filters?.integration_type}
          handleChanges={(ids) => {
            handleFilters('integration_ids', ids);
          }}
          value={tempFilters?.integration_ids || []}
        />
      ) : null}

      {!DisableFilterButton && (
        <div className='col-span-12 flex items-center gap-2 justify-between mt-4'>
          <div className='mr-2 flex items-center gap-4'>
            <Button type='primary' onClick={() => runFilter()}>
              Filter
            </Button>
            <Button
              icon={<img src='/icons/reset-orange.svg' className='w-3 h-3' />}
              onClick={resetFilter}
            >
              Reset
            </Button>
          </div>
        </div>
      )}
    </Space>
  );
};

export default WidgetFilter;
