import React, { Fragment } from 'react';
import {
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  AreaChart,
  Area,
  ResponsiveContainer,
} from 'recharts';
import { Typography, Flex } from 'antd';
import NoResultFound from 'components/charts/components/NoResultFound';
import CustomTooltip from './CustomTooltip';
import { metricsList } from 'modules/DashboardModule/charts/components/metricsList';
import getFormattedNumber from 'store/redux/utils/getFormattedNumber';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import { useWidget } from 'components/Widget/useWidget';
import './style.css';

const WidgetCharts = ({ data, index, label, totals }) => {
  const { chartConfig, widget_key } = useWidget();
  const { symbol, currency } = metricsList[index] || {};

  let formattedValue = totals && totals[index];
  if (symbol && totals) {
    formattedValue = `${getFormattedNumber(totals[index])}${symbol}`;
  } else if (currency && totals) {
    formattedValue = getFormattedValue(totals[index]);
  }

  const { valueFormatter, formatter } = metricsList[index] || {};
  const ValueFormatter = valueFormatter || Fragment;
  const FormatterComponent = formatter || Fragment;

  return (
    <div
      style={{ width: '100%' }}
      className='p-2 rounded-md border border-solid border-color-xlight '
    >
      <div className='flex items-center gap-2 justify-between'>
        <Flex gap='small' align='center'>
          <Typography level={6} className='text-dark-text font-normal !text-[10px]'>
            Total
          </Typography>
          <Typography.Text className='text-md font-medium'>{label}</Typography.Text>
        </Flex>

        <Typography variant='h1' className='text-md flex font-medium text-left sm:text-lg'>
          <div className='flex flex-wrap flex-center gap-2 show-info-on-hover !text-md'>
            {valueFormatter && totals ? (
              <ValueFormatter value={totals[index]} />
            ) : formatter && totals ? (
              <FormatterComponent row={totals} fontSize={16} />
            ) : (
              formattedValue
            )}
          </div>
        </Typography>
      </div>
      {data ? (
        <ResponsiveContainer width='100%' height='auto' aspect={4.0 / 1.0}>
          <AreaChart
            data={data}
            syncId={widget_key}
            margin={{
              right: chartConfig.showYAxis ? 20 : 0,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='date'
              height={20}
              hide={!chartConfig.showXAxis}
              interval={Math.ceil(data.length / 5)}
              tickFormatter={(value) =>
                new Date(value).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: '2-digit',
                })
              }
            />
            <YAxis margin={0} padding={0} width={30} hide={!chartConfig.showYAxis} />
            <Tooltip
              content={<CustomTooltip name={label} dataKey='date' value_key={index} />}
              cursor={{
                stroke: chartConfig?.areaConfig?.strokeColor || '#536A8B',
                strokeWidth: 1,
                strokeDasharray: '3 3',
              }}
            />
            <Area
              type='monotone'
              strokeWidth={2}
              dataKey={index}
              stroke={chartConfig?.areaConfig?.strokeColor || ' #536A8B'}
              fill={chartConfig?.areaConfig?.fillColor || '#F0F6FF'}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <NoResultFound height={100} />
      )}
    </div>
  );
};

export default WidgetCharts;
