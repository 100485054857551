import React from 'react';
import { Tooltip, Flex } from 'antd';
import clsx from 'clsx';
import { mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';

const SingleInfoRow = (props) => {
  const { tooltip, color = 'neutral', label, value } = props;

  return (
    <div className='flex justify-between items-center pb-2'>
      <Flex gap='small' align='center'>
        {label}
        {tooltip ? (
          <Tooltip zIndex={99999} arrow={true} title={tooltip}>
            <Icon path={mdiInformationOutline} className='!flex flex-center' size={0.7} />{' '}
          </Tooltip>
        ) : null}
      </Flex>
      <span
        className={clsx({
          'text-success': color === 'p',
          'text-error': color === 'n',
        })}
      >
        {value > 0 && color === 'p' ? '+' : ''}
        {getFormattedValue(value)}
      </span>
    </div>
  );
};

SingleInfoRow.propTypes = {};

export default SingleInfoRow;
