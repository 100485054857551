import React, { useState, useEffect, useCallback } from 'react';
import { useWidget } from '../useWidget';
import { Tag } from 'antd';
import { useDashboardLayout } from 'store/redux/states/dashboard/dashboard.hooks';
import { AdsTags, AccountsTags, AdsetsTags, CampaignsTags } from './tags';
import ShopifyAccountsTags from './ShopifyAccountsTags';
import WoocommerceAccountsTags from './WoocommerceAccountsTags';
import Icon from '@mdi/react';
import { mdiCloseCircle, mdiTrashCanOutline } from '@mdi/js';
import { Button, Divider, Flex } from 'antd';

const DisplayFilteredTag = ({ index, integrationType, default_filter = {}, handleDelete }) => {
  const { filters, setFilters, runFilter } = useWidget();
  const { config } = useDashboardLayout();
  const [defaultFilter, setDefaultFilter] = useState(filters);

  useEffect(() => {
    if (config[index]) {
      setDefaultFilter(filters);
    }
  }, [filters, config, index]);

  const handleCancel = useCallback(
    (field, value) => {
      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          [field]: Array.isArray(prevFilters[field])
            ? prevFilters[field].filter((item) => item !== value)
            : null,
        };
      });

      runFilter();
    },
    [default_filter, runFilter, setFilters]
  );

  return (
    <div>
      <div className='grid gap-2'>
        {defaultFilter?.statuses?.length > 0 && (
          <Flex gap='small' align='center'>
            <div className='text-sm'>Status:</div>
            {defaultFilter?.statuses?.map((status, idx) => (
              <Tag
                key={`status-${idx}`}
                bordered={false}
                closable
                closeIcon={<Icon path={mdiCloseCircle} />}
                onClose={() => handleCancel('statuses', status)}
                className='w-fit bg-background-light h-9 flex items-center rounded-2xl'
              >
                <span className='font-medium text-xs'>{status}</span>
              </Tag>
            ))}
          </Flex>
        )}

        {defaultFilter?.network_account_ids?.length > 0 && (
          <Flex gap='small'>
            <div className='text-sm'>Accounts:</div>
            <AccountsTags default_accounts={defaultFilter?.network_account_ids} />
          </Flex>
        )}
        {defaultFilter?.ad_ids?.length > 0 && (
          <Flex gap='small'>
            <div className='text-sm '>Ads:</div>
            <AdsTags default_ads={defaultFilter?.ad_ids} />
          </Flex>
        )}

        {defaultFilter?.adset_ids?.length > 0 && (
          <Flex gap='small'>
            <div className='text-sm'>Adsets:</div>
            <AdsetsTags default_adsets={defaultFilter?.adset_ids} />
          </Flex>
        )}
        {defaultFilter?.campaign_ids?.length > 0 && (
          <Flex gap='small'>
            <div className='text-sm'>Campaigns:</div>
            <CampaignsTags default_campaigns={defaultFilter?.campaign_ids} />
          </Flex>
        )}

        {integrationType === 'shopify' && defaultFilter?.integration_ids?.length > 0 && (
          <Flex gap='small'>
            <div className='text-sm'>Stores:</div>
            <ShopifyAccountsTags default_accounts={defaultFilter.integration_ids} />
          </Flex>
        )}

        {integrationType === 'woocommerce' && defaultFilter?.integration_ids?.length > 0 && (
          <Flex gap='small'>
            <div className='text-sm'>Stores:</div>
            <WoocommerceAccountsTags default_accounts={defaultFilter.integration_ids} />
          </Flex>
        )}
      </div>
      <Divider />
      <Button type='text' className='ml-auto' onClick={handleDelete}>
        <Icon path={mdiTrashCanOutline} className='text-color-light' size={0.8} />
      </Button>
    </div>
  );
};

export default DisplayFilteredTag;
