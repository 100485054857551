import React, { useMemo } from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import { RoiDefinition, RoasDefinition } from 'components/NameDefinition';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { mdiCashMultiple, mdiCreditCard, mdiBank } from '@mdi/js';
import {
  ValueDotFormatter,
  RoiFormatter,
  RoasFormatter,
} from 'components/tables/NinjaTable/formatters';
import WidgetCard from './WidgetCard';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import ReportInfo from 'components/ReportInfo/ReportInfo';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';

const NetworkWidget = ({ data, integration = null, network = null }) => {
  const { date_from, date_to, date_range_key } = useWidgetDate();

  const metrics = useMemo(() => {
    return [
      {
        icon: mdiCreditCard,
        label: 'Ad Spent',
        value: (
          <>
            {getFormattedValue(data?.ad_spent)}
            <ReportInfo
              type='ad_spent'
              info={{
                date_from,
                date_to,
                date_range_key,
                ...(integration && { integration }),
                ...(network && { network }),
              }}
            />
          </>
        ),
      },
      {
        icon: mdiBank,
        label: 'Gross Income',
        value: (
          <>
            <ValueDotFormatter value={data?.gross_income} />
            <ReportInfo
              type='gross_income'
              info={{
                date_from,
                date_to,
                date_range_key,
                ...(integration && { integration }),
                ...(network && { network }),
              }}
            />
          </>
        ),
      },
      {
        icon: mdiCashMultiple,
        label: 'Gross Profit',
        value: (
          <>
            {getFormattedValue(data?.gross_profit)}
            <ReportInfo
              type='gross_profit'
              info={{
                date_from,
                date_to,
                date_range_key,
                ...(integration && { integration }),
                ...(network && { network }),
              }}
            />
          </>
        ),
      },
      {
        icon: mdiCashMultiple,
        label: 'Net Profit',
        value: (
          <>
            {getFormattedValue(data?.net_profit)}
            <ReportInfo
              type='net_profit'
              info={{
                date_from,
                date_to,
                date_range_key,
                ...(integration && { integration }),
                ...(network && { network }),
              }}
            />
          </>
        ),
      },
      {
        label: <RoasDefinition showIcon />,
        formatter: () => <RoasFormatter row={data} fontSize={18} />,
      },
      {
        label: <RoiDefinition showIcon />,
        formatter: () => <RoiFormatter row={data} fontSize={18} />,
      },
    ];
  }, [data, date_from, date_to, date_range_key, integration, network]);

  return (
    <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4 m-auto items-center h-full w-full  overflow-x-auto'>
      {metrics.map((metric, index) => (
        <WidgetCard key={index} {...metric} />
      ))}
    </div>
  );
};

export default NetworkWidget;

NetworkWidget.propTypes = {
  data: PropTypes.object.isRequired,
};
