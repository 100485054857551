import React, { useEffect } from 'react';
import { useWidget } from 'components/Widget/useWidget';
import ChartBody from 'modules/DashboardModule/charts/components/ChartBody';
import ChartLoading from 'components/charts/components/ChartLoading';
import NoResultFound from 'components/charts/components/NoResultFound';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import ninjaApi from 'store/redux/apis';
import { getMin7DaysRange } from 'components/DateRangePicker/components/disableDateRange';

const metricsList = {
  avg_order_value: { color: '#3B6F3A', name: 'Avg. Order Value', currency: true, show: false },
  cac: { color: '#5A8F53', name: 'CAC', currency: true, show: false },
  avg_profit_per_order: {
    color: '#7A9E5E',
    name: 'Avg. Profit Per Order',
    currency: true,
    show: false,
  },
  new_customer_orders: { color: '#A0C68F', name: 'New Customer Orders', show: false },
  orders: { color: 'rgb(145, 199, 22)', name: 'Orders', show: false },
  cogs: { color: '#9DC08B', name: 'COGS', currency: true, show: false },
};

const ShopifyChartContent = ({ height, config, widget_key }) => {
  const [getIntegrationsReports, { data: shopify = [], isLoading }] =
    ninjaApi.useLazyIntegrationsReportsQuery();
  const { filters, chartConfig } = useWidget();
  const { date_from, date_to, setDate } = useWidgetDate(widget_key);

  useEffect(() => {
    let adjustedDateFrom = date_from;
    let adjustedDateTo = date_to;

    ({ date_from: adjustedDateFrom, date_to: adjustedDateTo } = getMin7DaysRange(
      date_from,
      date_to
    ));

    const params = {
      ...filters,
      integration: 'shopify',
      load_chart: chartConfig?.load_chart,
      date_from: adjustedDateFrom,
      date_to: adjustedDateTo,
    };

    if (chartConfig?.load_chart) {
      params.group_by = 'date';
    }

    if (filters?.date_from) {
      getIntegrationsReports(params)
        .unwrap()
        .then(() => {
          setDate({
            date_from: adjustedDateFrom,
            date_to: adjustedDateTo,
            date_key: widget_key,
          });
        });
    }
  }, [filters, chartConfig?.load_chart, date_to]);

  if (isLoading) {
    return <ChartLoading height={height} />;
  }

  if (!shopify?.data?.length) {
    return <NoResultFound height={height} />;
  }

  return (
    <ChartBody
      height={height}
      config={config}
      custommetricsList={{ ...metricsList }}
      data={shopify?.data}
    />
  );
};

export default ShopifyChartContent;
