import React, { useState, Fragment } from 'react';
import { Button, Popover, Flex, Badge } from 'antd';
import TableColumnCustomization from 'components/tables/NinjaTable/TableColumnCustomization';
import Icon from '@mdi/react';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';
import { mdiFilter, mdiViewColumnOutline, mdiDownloadOutline, mdiRefresh } from '@mdi/js';
import TableSearch from 'components/tables/NinjaTable/TableSearch';
import ExportButton from 'components/tables/NinjaTable/ExportButton';
import useQueryParams from 'hooks/useQueryParams';

const TableToolbar = (props) => {
  const {
    filterComponent: FilterComponent = Fragment,
    filterProps,
    wrapperPrependComponent,
  } = props;
  const { removeParams } = useQueryParams();

  const {
    filterCount,
    filters,
    customizationId,
    customizationEdit,
    onCustomizationUpdate,
    removeColumns,
    showSearch,
    exportData,
    enableFilters,
    filterKeys,
    clearFilter,
  } = useNinjaTable();

  const [activeOption, setActiveOption] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState(null);

  const handleOptionClick = (option) => {
    if (visiblePopover === option) {
      setVisiblePopover(null);
      setActiveOption(null);
    } else {
      setVisiblePopover(option);
      setActiveOption(option);
    }
  };

  const closePopover = () => {
    setVisiblePopover(null);
    setActiveOption(null);
  };

  return (
    <Flex gap='small' justify='space-between' align='center' wrap className='flex-wrap-reverse'>
      {wrapperPrependComponent}

      <Flex gap='small' align='center' wrap className='w-fit ml-auto' justify-content='end'>
        {filters &&
          enableFilters &&
          Object?.keys(filters)?.length > 0 &&
          !(
            (Object.keys(filters).length === 2 && 'date_from' in filters) ||
            'date_to' in filters
          ) && (
            <Button
              className='bg-error-background text-error'
              size='middle'
              type='text'
              shape='round'
              icon={<Icon path={mdiRefresh} />}
              onClick={() => {
                removeParams(filterKeys);
                clearFilter();
              }}
            >
              Reset
            </Button>
          )}
        {enableFilters && (
          <Popover
            content={<FilterComponent {...filterProps} closePopover={closePopover} />}
            trigger='click'
            placement='bottom'
            visible={visiblePopover === 'filter'}
            onVisibleChange={(visible) => {
              if (!visible) closePopover();
            }}
          >
            <Badge count={filterCount(filters)} size='small' offset={[-5, 4]}>
              <Button
                shape='round'
                type={activeOption === 'filter' ? 'primary' : 'default'}
                icon={<Icon path={mdiFilter} size={0.7} />}
                onClick={() => handleOptionClick('filter')}
              >
                Filter
              </Button>
            </Badge>
          </Popover>
        )}
        {customizationId && (
          <Popover
            content={
              <TableColumnCustomization
                customizationId={customizationId}
                onCustomizationUpdate={onCustomizationUpdate}
                customizationEdit={customizationEdit}
                removeColumns={removeColumns}
              />
            }
            trigger='click'
            visible={visiblePopover === 'column'}
            onVisibleChange={(visible) => {
              if (!visible) closePopover();
            }}
          >
            <Button
              shape='round'
              icon={<Icon path={mdiViewColumnOutline} size={0.7} />}
              type={activeOption === 'column' ? 'primary' : 'default'}
              onClick={() => handleOptionClick('column')}
            >
              Column
            </Button>
          </Popover>
        )}
        {exportData && (
          <Popover
            placement='bottom'
            content={<ExportButton />}
            trigger='click'
            visible={visiblePopover === 'export'}
            onVisibleChange={(visible) => {
              if (!visible) closePopover();
            }}
          >
            <Button
              shape='round'
              type={activeOption === 'export' ? 'primary' : 'default'}
              icon={<Icon path={mdiDownloadOutline} size={0.7} />}
              onClick={() => handleOptionClick('export')}
            >
              Export
            </Button>
          </Popover>
        )}
        {showSearch && (
          <div className='h-9'>
            <TableSearch className='h-full' />
          </div>
        )}
      </Flex>
    </Flex>
  );
};

export default TableToolbar;
