import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import useIntegrationStores from 'modules/apps/api/useIntegrationStores';

const WoocommerceAccountsTags = ({ default_accounts }) => {
  const { woocommerce } = useIntegrationStores();

  const WoocommerceAccountsTags = woocommerce?.filter((account) =>
    default_accounts.find((id) => id === account.id)
  );

  return (
    <>
      {WoocommerceAccountsTags.map((account) => (
        <Tag
          key={`account-${account.id}`}
          bordered={false}
          className='font-normal text-[11px] bg-background-light h-7 flex items-center rounded-2xl'
        >
          <div className='flex gap-1 items-center text-xs'>
            <img width='14' src='/icons/integrations/woocommerce.svg' alt={account.network} />
            <span className='font-medium flex-nowrap text-ellipsis'>{account.name}</span>
          </div>
        </Tag>
      ))}
    </>
  );
};

export default WoocommerceAccountsTags;

WoocommerceAccountsTags.propTypes = {
  default_accounts: PropTypes.array.isRequired,
};
