import {
  RoiFormatter,
  RoasFormatter,
  ValueDotFormatter,
  SpentFormatter,
} from 'components/tables/NinjaTable/formatters';

export const metricsList = {
  ad_spent: { color: '#eb2f20', name: 'Ad Spent', formatter: SpentFormatter, show: true },
  net_profit: {
    color: '#91c716',
    name: 'Net Profit',
    valueFormatter: ValueDotFormatter,
    show: true,
  },
  gross_profit: { color: '#536A8B', name: 'Gross Profit', valueFormatter: ValueDotFormatter },
  gross_income: { color: '#ffbb02', name: 'Gross Income', valueFormatter: ValueDotFormatter },
  roi: { color: '#ff4e00', name: 'ROI', type: 'line', formatter: RoiFormatter, opacity: 0.7 },
  roas: { color: '#F09319', name: 'ROAS', type: 'line', formatter: RoasFormatter, opacity: 0.7 },
};
