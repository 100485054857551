import { Alert, Button, Result, Typography } from 'antd';
import UrlHelper from 'common/utils/url-helper';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import P from 'router/paths';

const CompleteAccountConnectionFlowStep = () => {
  const flow = useAccountConnectionFlow();

  const history = useHistory();
  const activatedAccountsCount = flow.accounts.activatedAccounts?.length;

  return (
    <Fragment>
      <Result
        status='success'
        title='Successfully Connected Accounts'
        subTitle={`You have successfully connected ${activatedAccountsCount} accounts. It may take couple minutes to sync all data`}
        extra={[
          <Button
            type='primary'
            onClick={() => {
              history.push(P.DASHBOARD.INDEX);
            }}
            key='dashboard'
          >
            Go To Dashboard
          </Button>,
          <Button
            key='connect'
            onClick={() => {
              flow.setConnectionBatch(null);
              flow.changeStep('connect');
            }}
          >
            Connect More Accounts
          </Button>,
        ]}
      />
      <div className='w-full flex items-center justify-center'>
        {flow.app === 'meta' && (
          <Alert
            message='Auto tracking is not avialable for meta ad accounts. You have to do it manually.'
            type='warning'
            showIcon
            className='w-full md:w-1/2'
            description={
              <Typography.Link href={UrlHelper.docs('/docs/tracking/meta-ads')} target='_blank'>
                {' '}
                Learn How To Track
              </Typography.Link>
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default CompleteAccountConnectionFlowStep;
