import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import {
  useAcceptInviteMutation,
  useLazyGetInvitationByTokenQuery,
} from 'store/redux/apis/workspace/permissions.api';
import url from '@ninja/url';
import { Card, Typography } from 'antd';
import UrlHelper from 'common/utils/url-helper';
import { apps, appTypes } from 'modules/apps/apps.config';

const GuestConnectionPage = () => {
  const connection_token = url.get('connection_token');
  const history = useHistory();
  const [getInvitation, { data: invitation, isLoading: isInvitationLoading }] =
    useLazyGetInvitationByTokenQuery();

  useEffect(() => {
    getInvitation({
      hash: connection_token,
    })
      .unwrap()
      .then((d) => {
        console.log(d);
      });
  }, [connection_token]);

  const redirectToLogin = () => {
    history.push(P.AUTH.LOGIN);
  };

  const redirectToDashboard = () => {
    history.push(P.DASHBOARD.INDEX);
  };

  return (
    <div className='w-full h-full p-4 md:p-8 overflow-auto flex flex-center min-h-[100%] bg-background-dark '>
      <div className='flex flex-col w-full h-full mx-auto items-center'>
        <a className='flex no-underline flex-col' href={UrlHelper.home()}>
          <img className='m-auto w-[150px]' src='/icons/dataninja/dataninja.svg' />
          <Typography.Title level={1} className='!text-white'>
            MyDataNinja
          </Typography.Title>
        </a>

        <div className='bg-white p-5 rounded-md shadow-md transition-all duration-300 w-full md:w-1/3 lg:w-1/5'>
          <form className='disable-antd-margin'>
            <div className='grid gap-6'>
              <div className='text-center'>
                <p className='text-base font-normal'>Connect Accounts To Workspace</p>
              </div>

              <div className='flex flex-col gap-4'>
                {Object.entries(apps).map(([key, app]) => {
                  if (!app.type.includes(appTypes.MARKETING)) return null;

                  return (
                    <Card key={key}>
                      <div className='flex items-center'>
                        <div className='w-full '>
                          <img width={50} src={app.image} alt={app.name} />
                        </div>
                        <div className='w-full justify-start flex-grow'>{app.name}</div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GuestConnectionPage;
