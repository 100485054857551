import React, { useMemo, useState } from 'react';
import NinjaTable from 'components/tables/NinjaTable';
import {
  AdFormatter,
  AdsetFormatter,
  CampaignFormatter,
} from 'components/tables/NinjaTable/formatters/sourceFormatters';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import Widget from 'components/Widget/Widget';
import WidgetsCommonColumns from 'components/widgets/components/WidgetsCommonColumns';

const WorstAds = ({ height, movingButton, index, data, configuration }) => {
  const { dates } = useWidgetDate(`worst_ads${index}`);
  const [widgetLevel, setWidgetLevel] = useState(data?.defaultFilter?.level || 'adset');

  const levelColumns = useMemo(
    () => ({
      adset: {
        key: 'network_Adset.name',
        name: 'Adset',
        renderCell: AdsetFormatter,
      },
      campaign: {
        key: 'network_campaign.name',
        name: 'Campaign',
        renderCell: CampaignFormatter,
      },
      ad: {
        key: 'network_ad.name',
        name: 'Ad',
        renderCell: AdFormatter,
      },
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        ...levelColumns[widgetLevel],
        resizable: true,
        sortable: true,
        cellClass: 'stretch',
        minWidth: 250,
        frozen: true,
        renderSummaryCell: () => 'Totals',
      },
      ...WidgetsCommonColumns(),
    ],
    [widgetLevel, levelColumns]
  );

  return (
    <Widget
      onLevelChange={(level) => setWidgetLevel(level)}
      tableWidget
      widget_key={'worst_ads' + index}
      title={data.title}
      movingButton={movingButton}
      deleteWidget
      index={index}
      data={data}
      default_filter={data.defaultFilter}
      configuration={configuration}
      adsFilter
      adsetFilter
      campaignFilter
      disableDateFilter
      customize
      DisableFilterButton={false}
      showDatePicker
    >
      <NinjaTable
        columns={columns}
        dataProvider='dashboardWorstAds'
        queryParams={{ ...dates }}
        cardProps={{ disable: true }}
        disableFooter={true}
        maxHeight={height}
        tableProps={{ template: 'widget' }}
      />
    </Widget>
  );
};

export default WorstAds;
