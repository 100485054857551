import React, { useEffect } from 'react';
import { Typography } from 'antd';
import UrlHelper from 'common/utils/url-helper';
import { calendlyLink } from '@ninja/consts';

const BookDemoPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    script.setAttribute('id', 'calendly-script');
    script.async = true;

    document.head.appendChild(script);
  }, []);

  return (
    <div className='w-full h-full p-4 md:p-8 overflow-auto flex flex-center min-h-[100%] bg-background-dark '>
      <div className='flex flex-col w-full h-full mx-auto items-center'>
        <a className='flex no-underline flex-col' href={UrlHelper.home()}>
          <img className='m-auto w-[150px]' src='/icons/dataninja/dataninja.svg' />
          <Typography.Title level={1} className='!text-white'>
            MyDataNinja
          </Typography.Title>
        </a>
        <div
          className='calendly-inline-widget'
          data-url={calendlyLink}
          style={{ minWidth: 320, height: 700, width: '100%' }}
        ></div>
      </div>
    </div>
  );
};

export default BookDemoPage;
