import StyledCard from 'components/Cards/StyledCard';
import React, { Fragment } from 'react';

const NinjaTableWrapper = (props) => {
  const {
    component: Component = StyledCard,
    title,
    icon,
    padding = [0, 0],
    header,
    disable,
    ...rest
  } = props;

  // If card is disabled in table
  if (disable) {
    return <Fragment>{props.children}</Fragment>;
  }

  // If card is not disbaled then rendered StyledCard component by default
  // User can change wrapper component by passing wraper prop to NinjaTable
  return (
    <Component title={title} avatar={icon} padding={padding} header={header} {...rest}>
      {props.children}
    </Component>
  );
};

export default NinjaTableWrapper;
