import React from 'react';
import { Card, Flex, Typography } from 'antd';

const StyledCard = (props) => {
  const { header = true, headerProps, title, avatar, children, padding, ...rest } = props;

  return (
    <Card className='shadow-sm !rounded-md !h-fit' bodyStyle={{ padding: 0 }} {...rest}>
      <Flex
        align='center'
        gap='small'
        justify='space-between'
        className='pl-7 pr-3 py-3 h-14'
        style={{ borderBottom: '1px solid rgb(235, 235, 235)' }}
      >
        <Card.Meta
          className='flex items-center'
          avatar={avatar ? <div className='text-orange flex align-center'>{avatar}</div> : null}
          title={<Typography className='text-sm font-bold'>{title}</Typography>}
          {...headerProps}
        />
        <Flex align='center' gap='small'>
          {header}
        </Flex>
      </Flex>
      <div style={{ padding: padding ? `${padding[0]}rem` : '0' }}>{children}</div>
    </Card>
  );
};

export default StyledCard;
